
import api from "../../api";

const baseUrl = "/api/notification";

export const getNotifications = () => {
  return api.get(baseUrl);
};

export const getPaginatedNotifications = (page, limit, search = "", userId, userProject, role, startDate, endDate, Status) => {
  return api.get(`${baseUrl}`, {
    params: {
      page,
      limit,
      search,
      userId,
      userProject,
      role,
      startDate,
      endDate,
      Status
    },
    withCredentials: true, // Ensure it is inside the second object
  });
};



export const getTodayNotifications = (queryText) => {

  // Append the query as a query parameter if provided
  const queryParam = queryText
    ? `?role=${encodeURIComponent(queryText.role)}&userProject=${encodeURIComponent(queryText.userProject)}&userId=${encodeURIComponent(queryText.userId.trim().replace(/}$/, ''))}`
    : '';

  return api.get(`${baseUrl}/Today${queryParam}`);
};

export const markNotificationAsRead = (updateData) => {

  return api.put(`/api/notification/${updateData.notificationId}`, {
    readStatusId: updateData.readStatusId,
    userId: updateData?.userId,
    role: updateData?.role
  });
};



export const addNotification = (postData) => {
  return api.post(baseUrl, postData);
};

export const updateNotification = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData);
};

export const deleteNotification = (deleteId, items) => {
  return api.delete(`${baseUrl}/${deleteId}`, { data: { items } });
};
