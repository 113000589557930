import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProviderUserTable from './ProviderUserTable';
import { setLoading } from '../../redux/loading/loadingSlice';
import SearchBar from '../../components/SearchBar';
import { Button, Col, notification, Row } from 'antd';
import CreateProviderUser from './CreateProviderUser';
import EditProviderUser from './EditProviderUser';
import DeleteProviderUser from './DeleteProviderUser';
import { getTimeZonesApi } from '../../redux/timeZone/timeZoneService';
import usePaginatedExternalUsers from '../../pagination/usePaginatedExternalUsers';

const ProviderUser = () => {
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState('');
  const [createStatus, setCreateStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const timeZoneList = useSelector((state) => state.timeZone.timeZoneList);

  const { providerList, currentPage, pageSize, totalUsers, setCurrentPage, setPageSize, refreshUsers, setSearchTerm } = usePaginatedExternalUsers();

  useEffect(() => {
    const fetchData = async () => {
      if (timeZoneList?.length === 0) { // Fetch time zones if not already loaded
        dispatch(setLoading(true));
        try {
          await dispatch(getTimeZonesApi()).unwrap();
        } catch (error) {
          console.error("Error fetching time zones:", error);
        } finally {
          dispatch(setLoading(false));
        }
      }
    };

    fetchData();
  }, [dispatch, timeZoneList.length]); // Only run if timeZoneList is empty

  const selectTimeZoneList = useMemo(() => {
    return timeZoneList
      .map((item) => ({ value: item.id, label: item.name }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [timeZoneList]);

  return (
    <div className='w-full h-full p-2'>
      <Row gutter={[16, 16]} justify="center" align="middle">
        <Col xs={24} sm={18} md={18} lg={18} xl={18}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={18} lg={18} xl={18}>
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                setSearchTerm={setSearchTerm}
              />
            </Col>
            <Col xs={24} sm={6} md={6} lg={6} xl={6}>
              <Button
                className='bg-blue-500 text-white w-auto'
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  if (totalUsers === 3) {
                    return notification.warning({
                      message: 'Max User Limit Reached',
                      description: 'If you need additional users, kindly reach the admin of WWS Facility.',
                    });
                  }
                  setCreateStatus(true);
                }}
              >
                Add User
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="mt-2 overflow-auto max-h-[61vh] no-scrollbar">
        <div className="min-w-[600px] sm:min-w-full">
          <Row gutter={[16, 16]} justify="center" align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <ProviderUserTable
                providerList={providerList}
                searchQuery={searchQuery}
                setEditStatus={setEditStatus}
                setEditData={setEditData}
                setDeleteStatus={setDeleteStatus}
                setDeleteData={setDeleteData}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                currentPage={currentPage}
                pageSize={pageSize}
                totalUsers={totalUsers}
              />
            </Col>
          </Row>
        </div>
      </div>
      {createStatus && (
        <CreateProviderUser
          createStatus={createStatus}
          setCreateStatus={setCreateStatus}
          providerList={providerList}
          selectTimeZoneList={selectTimeZoneList}
          refreshUsers={refreshUsers}
        />
      )}
      {
        editStatus && (
          <EditProviderUser
            editStatus={editStatus}
            setEditStatus={setEditStatus}
            editData={editData}
            setEditData={setEditData}
            providerList={providerList}
            selectTimeZoneList={selectTimeZoneList}
            refreshUsers={refreshUsers}
          />
        )
      }
      {
        deleteStatus && (
          <DeleteProviderUser
            deleteStatus={deleteStatus}
            setDeleteStatus={setDeleteStatus}
            deleteData={deleteData}
            setDeleteData={setDeleteData}
            refreshUsers={refreshUsers}
          />
        )
      }

    </div>
  );
};

export default ProviderUser;
