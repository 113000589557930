import React, { useState } from 'react';
import { Row, Col } from 'antd';
import RoleTable from './RoleTable';
import RoleHeader from './RoleHeader';

const Role = () => {
  const [searchQuery, setSearchQuery] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState('');

  const roleList = [
    {
      "id": 1,
      "name": "Provider",
    },
    {
      "id": 2,
      "name": "VIEW",
    },
    {
      "id": 3,
      "name": "DOWNLOAD",
    }
  ];

  return (
    <div className="w-full h-full p-2">
      <Row gutter={[16, 16]} justify="center" align="middle">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <RoleHeader
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setSearchTerm={setSearchTerm}
          />
        </Col>
      </Row>
      <div className="mt-2 overflow-auto max-h-[61vh] no-scrollbar">
        <div className="min-w-[600px] sm:min-w-full">
          <Row gutter={[16, 16]} justify="center" align="middle">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <RoleTable
                roleList={roleList}
                searchQuery={searchQuery}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Role