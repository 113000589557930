import { createAsyncThunk } from '@reduxjs/toolkit';
import {getRecentHistories, createOrUpdateRecentHistory} from '../../api/recentHistory/recentHistory';


export const getRecentHistoryApi = createAsyncThunk(
  "recentHistory/get",
  async ({ userId, projectId, page, pageSize ,searchTerm}, { rejectWithValue }) => {
    try {
      const response = await getRecentHistories(userId, projectId, page, pageSize,searchTerm);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);


  export const createOrUpdateRecentHistoryApi = createAsyncThunk(
     'recentHistory/createOrUpdate',
     async({ userId, chartId , projectId},{rejectWithValue}) => {
        try {
            const response = await createOrUpdateRecentHistory(userId, projectId, chartId);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error
              }
              return rejectWithValue(error.response.data);
        }
     }
  );