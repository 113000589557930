import api from "../../api";

const baseUrl = "/api/provider";

export const getProviders = () => {
  return api.get(baseUrl);
};

export const addProvider = (postData) => {
  return api.post(baseUrl, postData);
};

// export const updateProvider = (updateId, putData) => {
//   return api.put(`${baseUrl}/${updateId}`, putData);
// };

export const updateProvider = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData, { withCredentials: true });
};

export const deleteProvider = (deleteId) => {
  return api.delete(`${baseUrl}/${deleteId}`);
};

export const getPaginatedExternalUsers = (page, limit, searchTerm, role, project, userId) => {
  return api.get(`${baseUrl}/users`, {
    params: {
      page,
      limit,
      search: searchTerm,
      role: role,
      project: project,
      userId: userId,
    },
    withCredentials: true, // Ensure it is inside the second object
  });
};

export const getAvatarPresignedUrl = (fileExtension, fileType) => {
  return api.post(`${baseUrl}/presigned_url`, {
    fileExtension,
    fileType,
  });
};