import Axios from "axios";
import api from "../../api";

const baseUrl = "/api/document";

export const getDocuments = () => {
  return api.get(baseUrl, {
    withCredentials: true, // Ensures credentials (cookies, etc.) are sent with the request
  });
};


export const getTodayDocuments = () => {
  return api.get(`${baseUrl}/TodayDocuments`);
}

export const getPaginatedDocuments = (page, limit, searchTerm, project, userId, role) => {
  return api.get(`${baseUrl}/documents`, {
    params: {
      page,
      limit,
      search: searchTerm,
      project,
      userId,
      role
    },
    withCredentials: true, // Ensure it is inside the second object
  });

};

// export const getPaginatedDocuments = (page, limit, searchTerm, project, userId, role) => {
//   return api.get(`${baseUrl}/documents`, {
//     params: {
//       page,
//       limit,
//       search: searchTerm,
//       project,
//       userId,
//       role
//     },
//   }, { withCredentials: true });
// };

export const viewDocument = (id) => {
  return api.get(`${baseUrl}/${id}`);
};

export const addDocument = (postData) => {
  return api.post(baseUrl, postData);
};

export const updateDocument = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData);
};

export const deleteDocument = (deleteId) => {
  return api.delete(`${baseUrl}/${deleteId}`);
};

export const getPresignedUrl = (id, type) => {
  return api.post(`${baseUrl}/presignedUrl`, { id, type });
};

export const uploadFile = (url, file, cb) => {
  const controller = new AbortController();
  return Axios.put(url, file.fileObject, {
    headers: {
      "Content-Type": file.type,
    },
    onUploadProgress: (e) => {
      cb(file, controller, {
        uploaded: e.loaded,
        total: e.total,
        progress: Math.floor((e.loaded / e.total) * 100),
      });
    },
    signal: controller.signal,
  });
};