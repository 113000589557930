import Axios from "axios";
import api from "../../api";

const baseUrl = "/api/exclusionDocument";

export const getExclusiveDocument = () => {

  return api.get(baseUrl, { withCredentials: true });

}

export const createNewExclusionChart = (id, postData) => {
  return api.put(`${baseUrl}/createNewExclusionChart/${id}`, { postData });
};

export const createExclusiveChart = (postData) => {
  return api.post(`${baseUrl}/createExclusiveChart`, postData);
};

export const deleteExclusiveDocument = (deleteId, documentId) => {
  return api.delete(`${baseUrl}/${deleteId}`, {
    data: { documentId }  // Pass documentId in the request body
  });
}

export const updateExclusiveDocument = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData);
};

export const getPresignedUrl = (id, type) => {
  return api.post(`${baseUrl}/presignedUrl`, { id, type });
};

export const uploadFile = (url, file, cb) => {
  const controller = new AbortController();
  return Axios.put(url, file.fileObject, {
    headers: {
      "Content-Type": file.type,
    },
    onUploadProgress: (e) => {
      cb(file, controller, {
        uploaded: e.loaded,
        total: e.total,
        progress: Math.floor((e.loaded / e.total) * 100),
      });
    },
    signal: controller.signal,
  });
};