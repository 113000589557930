import React from 'react';
import { Row, Col } from 'antd';
import SearchBar from '../../components/SearchBar';


const RoleHeader = ({ searchQuery, setSearchQuery, setSearchTerm }) => {
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setSearchTerm={setSearchTerm}
        />
      </Col>
    </Row>
  );
};

export default RoleHeader;
