import React, { lazy, Suspense } from 'react';
import {
  Routes,
  Route,
  BrowserRouter
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

import SignIn from './pages/SignIn/SignIn.js';
import Loading from './components/Loading.js';
import ProviderIndex from './pages/Provider/Index.js';
import ProviderSignInPage from './pages/SignIn/ProviderSignInPage.js';
import ProviderProtectedRoute from './utils/ProviderProtectedRoute/ProviderProtectorRoute.js';
import ProtectorRoute from './utils/ProtectedRoutes/ProtectorRoute.js';


const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword/ForgotPassword.js'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword/ResetPassword.js'));
const ResetDone = React.lazy(() => import('./pages/ResetDone/ResetDone.js'));
const TwoFactor = React.lazy(() => import('./pages/TwoFactor/TwoFactor.js'));
const Policy = React.lazy(() => import('./pages/Policy/PolicyNEw'));
const AccountLockout = React.lazy(() => import('./pages/AccountLockout/AccountLockout.js'));
const EmailChange = React.lazy(() => import('./pages/Email Change/EmailChange.js'));
const PrivacyPolicy = React.lazy(() => import('./pages/Privacypolicy/PrivacyPolicy.js'));
const Chart = lazy(() => import('./pages/Chart/Index.js'));
const SuperAdminIndex = lazy(() => import('./pages/SuperAdmin/SuperAdminIndex.js'));
const Admin = lazy(() => import('./pages/Admin/IndexNew.js'));
const UsersIndex = lazy(() => import('./pages/Users/IndexNew.js'));
const NotificationCenter = lazy(() => import('./pages/Notification/NotificationCenter.js'));
const BatchConfirmPage = lazy(() => import('./pages/BatchConfirmPage/BatchConfirmPage.js'));

const App = () => {
  const { isLoading } = useSelector((state) => state.loading);

  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
      />
      <BrowserRouter>
        <Suspense fallback={<Loading spinning={isLoading} />}>
          <Routes>
            <Route exact path="/" element={<ProviderSignInPage />} />
            <Route exact path="/forGetPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route exact path="/ResetPasswordDone" element={<ResetDone />} />
            <Route exact path="/twoFactor" element={<TwoFactor />} />
            <Route path="/policy/:id" element={<Policy />} />
            <Route exact path="/internal" element={<SignIn />} />
            <Route path="/unlock-account" element={<AccountLockout />} />
            <Route exact path="/provider/*" element={<ProviderIndex />} />
            <Route exact path="/ResetPasswordDone" element={<ResetDone />} />
            <Route path="/chart" element={<Chart />} />
            {/* <Route path="/super-admin/*" element={<ProtectorRoute allowedRoles={['SUPER ADMIN', 'GUEST']}> <SuperAdmin /></ProtectorRoute>} /> */}
            <Route path="/super-admin/*" element={<ProtectorRoute allowedRoles={['SUPER ADMIN', 'GUEST']}><SuperAdminIndex /></ProtectorRoute>} />
            {/* <Route path="/admin/*" element={<ProtectorRoute allowedRoles={['ADMIN', 'GUEST']}> <Admin /></ProtectorRoute>} /> */}
            <Route path="/admin/*" element={<ProtectorRoute allowedRoles={['ADMIN', 'GUEST']}><Admin /></ProtectorRoute>} />
            <Route path="/batchPage/*" element={<ProtectorRoute allowedRoles={['VERIFICATION', 'VERIFICATION CALLER', 'AUDIT', 'VERIFICATION CALLER', 'AUDIT CALLER']}><BatchConfirmPage /></ProtectorRoute>} />
            <Route path="/notification" element={<NotificationCenter />} />
            {/* <Route path="/users/*" element={<ProtectorRoute allowedRoles={['VERIFICATION', 'AUDIT', 'VERIFICATION CALLER', 'AUDIT CALLER', 'CODING']}><UsersIndex /></ProtectorRoute>} /> */}
            <Route path="/users/*" element={<ProtectorRoute allowedRoles={['VERIFICATION', 'AUDIT', 'VERIFICATION CALLER', 'AUDIT CALLER', 'CODING']}><UsersIndex /></ProtectorRoute>} />
            <Route path="/emailChange" element={<EmailChange />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />

          </Routes>
        </Suspense>
      </BrowserRouter>
      <Loading spinning={isLoading} />
    </>
  );
}

export default App;
