/// Retrival of full documents in data group section with pagination

import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../redux/loading/loadingSlice';
import { getPaginatedExternalUsers } from '../api/provider/provider';

const usePaginatedExternalUsers = (initialPage = 1, initialPageSize = 10, initialSearchTerm = '') => {
  const dispatch = useDispatch();
  const [providerList, setProviderList] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [totalUsers, setTotalUsers] = useState(0);
  // const [getPagedBatchNos, setGetPagedBatchNos] = useState([]);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  const userDetails = useSelector((state) => state.userDetails)
  
  const { role, project, userId } = userDetails;
  
  const fetchDocuments = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const response = await getPaginatedExternalUsers(currentPage, pageSize, searchTerm, role, project, userId);
      setProviderList(response?.data?.users || []);
      setTotalUsers(response?.data?.totalUsers || 0);
    } catch (error) {
      setError(error);
    } finally {
      dispatch(setLoading(false));
    }
  }, [currentPage, dispatch, pageSize, project, role, searchTerm, userId]);

  // Function to refresh documents
  const refreshUsers = useCallback(() => {
    fetchDocuments(); // Calls the function to fetch documents
  }, [fetchDocuments]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    setCurrentPage(1); // Reset to the first page when search term changes
  };

  return {
    providerList,
    error,
    currentPage,
    pageSize,
    totalUsers,
    setCurrentPage,
    setPageSize,
    // getPagedBatchNos,
    refreshUsers,
    setSearchTerm: handleSearchChange,
  };
};

export default usePaginatedExternalUsers;
