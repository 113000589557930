/* eslint-disable jsx-a11y/alt-text */
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Routes, Route, useNavigate, useLocation, Link, Navigate, } from "react-router-dom";
import {
  PieChartOutlined,
  RightOutlined,
  EditOutlined
} from "@ant-design/icons";
import {
  Menu, Layout, Dropdown, Avatar, Breadcrumb, Upload, Image,
  Grid,
  Button,
  Tooltip
} from "antd";

import Dashboard from "../CommonDashboard/Dashboard";
import { HomeOutlined } from "@ant-design/icons";
// import Profile from "./Profile/Profile";
import { useDispatch, useSelector } from "react-redux";
import { clearUserDetails } from '../../redux/userDetails/userDetailsSlice';
import ModalBox from "../../components/ModalBox";
import { DownOutlined } from '@ant-design/icons';
import { IoIosHelpCircleOutline, IoMdDocument } from "react-icons/io";
import { AiFillInteraction } from "react-icons/ai";
import { MdOutlineBook, MdScheduleSend } from "react-icons/md";
import { CgPathExclude } from "react-icons/cg";
import { ToolOutlined } from '@ant-design/icons';
import { LuCalculator, LuGanttChartSquare } from "react-icons/lu";
import ProviderDirectoryTabs from "../UserDirectoryTabs/ProviderDirectoryTabs.js";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FiUserCheck } from "react-icons/fi";
import AntFooter from "../../components/AntFooter.js";
import InactivityProvider from "../../components/Inactivity/InactivityProvider.js"
import ProtectorRoute from "../../utils/ProviderProtectedRoute/ProviderProtectorRoute.js";
import logo from "../../assets/svg_images/BM.svg";
import { HiClock } from "react-icons/hi";
import { RecentHistory } from "./RecentHistory.js";
import UpdatePatient from "../Chart/UpdatePatient.js";
import { MdNotifications } from "react-icons/md";
import { globalHeaderHeight } from "../../constants/constants.js";
import Notification from "../Notification/Notification.js";
import { AiFillSetting } from "react-icons/ai";
import { FaQuestion, FaUser } from "react-icons/fa";
import Home from "../CommonHome/Home.js";
import { RiGuideLine } from "react-icons/ri";
import { RiFeedbackLine } from "react-icons/ri";

const { Header, Sider, Content } = Layout;
const { useBreakpoint } = Grid;



const Chart = lazy(() => import('../Admin/Chart/Chart.js'));
const Batch = lazy(() => import('../SuperAdmin/Batch/Batch'));
const Document = lazy(() => import("../Admin/Document/Document"));
const Exclusion = lazy(() => import("../SuperAdmin/ExclusionList/Exclusion.js"));
const UserGuide = lazy(() => import("../UserGuide/UserGuide.js"));
const GlossaryTermsComponent = lazy(() => import("./GlossaryTermsComponent.js"));
const FAQComponent = lazy(() => import("./FAQComponent.js"));
// Lazy-load the BarCharts component with a delay of 20 minutes
// const Document = React.lazy(() =>
//   new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(import("../Admin/Document/Document")); // Dynamically import the component after 20 minutes
//     }, 1 * 60 * 1000); // 20 minutes in milliseconds (1,200,000 ms)
//   })
// );
const Profile = lazy(() => import("./Profile/Profile"));
const ExclusionTableView = lazy(() => import("../SuperAdmin/ExclusionList/ExclusionTableView.js"));
const DMEPaymentCalculator = lazy(() => import("../../components/PaymentCalculator.js"));
const RepositoryDirectoryTabs = lazy(() => import("../CommonReportRepository/RepositoryDirectoryTabs.js"));
const PhysicianDetails = lazy(() => import("../../components/PhysicainDetails"));
const PhysicianNpiLookup = lazy(() => import("../../components/PhysicianNpiLookup.js"));
const NotificationCenter = lazy(() => import("../Notification/NotificationCenter.js"));
const FeedbackRedirect = lazy(() => import("./FeedBack.js"));




const layoutStyle = {
  minHeight: '100vh',
  overflowY: 'hidden',
};

const siderStyle = {
  background: 'white',
  padding: '16px 0',
  height: '100vh',
  overflow: 'auto',
};

const headerStyle = {
  padding: '0 16px',
  background: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#000',
  height: globalHeaderHeight
};

const contentStyle = {
  margin: '16px',
  padding: '8px',
  background: 'white',
  color: '#fff',
  borderRadius: '8px',
  flexGrow: 1,
  overflow: 'hidden',
};


let toolTipPosition = "rightTop"


const ProviderIndex = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const isMobile = !screens.md; // Checks if the screen size is below medium breakpoint
  const [collapsed, setCollapsed] = useState(true);
  const [logOut, SetLogOut] = useState(false)
  const [selectedKey, setSelectedKey] = useState('');
  const [tabName, setTabName] = useState('Users');
  const [reportTabName, setReportTabName] = useState('Report Viewer & Scheduler');
  const { firstName, lastName, projectName, role, userAccess } = useSelector((state) => state.userDetails);
  const [open, setOpen] = useState(false);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const roleMapping = {
    'PROVIDER': 'Client Administrator',
    'USER': `${userAccess === "VIEW" ? "Visitor Viewer" : "Report Manager"}`,
    'DOWNLOAD': 'Report Manager',

  };

  const getMappedRoleName = (name) => roleMapping[name] || name;

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const [iconVisible, setIconVisible] = useState(false);
  const { providerList } = useSelector((state) => state.provider);
  const project = useSelector((state) => state.userDetails.project);
  const avatarUrl = useSelector((state) => state.userDetails.avatarUrl);


  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [editPatientViewStatus, setEditPatientViewStatus] = useState(false);
  const [editPatientData, setEditPatientData] = useState("");
  const { chartList } = useSelector((state) => state.chart);

  const navigationItems = useMemo(() => [
    {
      key: '/provider/home',
      icon: <HomeOutlined />,
      label: <>
        <Tooltip title="Access the main hub to manage tasks and navigate the system" placement={toolTipPosition}>
          <span >Home</span>
        </Tooltip>
      </>,
    },
    {
      key: '/provider/dashboard',
      icon: <PieChartOutlined />,
      label: <>
        <Tooltip title="Access a summary of system insights and performance indicators" placement={toolTipPosition}>
           <span >Dashboard</span>
        </Tooltip>
      </>,
    },
    {
      key: 'e&b',
      icon: <AiFillInteraction />,
      label: "Eligibility & Benefits",
      children: [
        {
          key: '/provider/document',
          icon: <IoMdDocument />,
          label: <>
            <Tooltip title="View a categorized collection of patient information as received" placement={toolTipPosition}>
              <span>Data Groups</span>
            </Tooltip>
          </>,
        },
        {
          key: '/provider/chart',
          icon: <LuGanttChartSquare />,
          label: <>
            <Tooltip title="Access in-depth chart data, including patient metrics, equipment needs, and case details" placement={toolTipPosition}>
              <span >Charts Insights</span>
            </Tooltip>
          </>,
        },
        {
          key: `/provider/projectDetails/${project}`,
          icon: <AiOutlineFileSearch />,
          label: <>
            <Tooltip title="Access every chart from a single, unified view." placement={toolTipPosition}>
              <span >All Chart View</span>
            </Tooltip>
          </>,
        },
      ]
    },
    {
      key: 'settings',
      icon: <AiFillSetting />,
      label: "Settings",
      children: [
        ...(role !== "USER" && role !== "DOWNLOAD" ? [{
          key: '/provider/user-directory',
          icon: <FaUser />,
          label: <>
            <Tooltip title="Access the complete list of users to update profiles or control access." placement={toolTipPosition}>
              <span >User Directory</span>
            </Tooltip>
          </>,
        }] : []),

        {
          key: '/provider/notification',
          icon: <MdNotifications />,
          label: <>
            <Tooltip title="Get notified about essential updates and system actions." placement={toolTipPosition}>
               <span > Notifications</span>
            </Tooltip>
          </>,
        },

        {
          key: '/provider/exclusionList',
          icon: <CgPathExclude />,
          label: <>
            <Tooltip title="Manage payers excluded from the facility due to non-payment or other specified reasons" placement={toolTipPosition}>
               <span > Payer Exclusion List</span>
            </Tooltip>
          </>,
        },
        {
          key: '/provider/repository-directory',
          icon: <MdScheduleSend />,
          label: <>
            <Tooltip title="View, manage, and download all saved reports in one place" placement={toolTipPosition}>
               <span > Report Repository</span>
            </Tooltip>
          </>,
        },
        {
          key: '/provider/2fa',
          icon: <EditOutlined />,
          label: <>
            <Tooltip title="Get notified about essential updates and system actions." placement={toolTipPosition}>
                <span > Edit Profile</span>
            </Tooltip>
          </>,
        },
        {
          key: "/provider/recentHistory",
          icon: <HiClock />,
          label: <>
            <Tooltip title="Access detailed records of activities logged over the last 7 days." placement={toolTipPosition}>
                <span > Recent History</span>
            </Tooltip>
          </>,
        }
      ]
    },
    {
      key: 'tools',
      icon: <ToolOutlined />,
      label: "Tools",
      children: [
        {
          key: '/provider/paymentCalculator',
          icon: <LuCalculator />,
          label: <>
            <Tooltip title="Estimate out-of-pocket costs by factoring in deductibles, copays, and coinsurance" placement={toolTipPosition}>
               <span > Payment Calculator</span>
            </Tooltip>
          </>,
        },
        {
          key: '/provider/npiValidation',
          icon: <FiUserCheck />,
          label: <>
            <Tooltip title="Validate NPI numbers using real-time data from the official NPPES system" placement={toolTipPosition}>
              <span > NPI Validation</span>
            </Tooltip>
          </>,
        }
      ]
    },
    {
      icon: <IoIosHelpCircleOutline />,
      label: "Help Center",
      children: [
        {
          key: '/provider/faq',
          icon: <FaQuestion />,
          label: <>
            <Tooltip title="Access helpful FAQs to navigate the system with ease." placement={toolTipPosition}>
              <span > FAQ</span>
            </Tooltip>
          </>,
        },
        {
          key: '/provider/glossary',
          icon: <MdOutlineBook />,
          label: <>
            <Tooltip title="Access a comprehensive list of terms and definitions relevant to the system" placement={toolTipPosition}>
              <span > Glossary</span>
            </Tooltip>
          </>,
        },
        {
          key: '/provider/userGuide',
          icon: <RiGuideLine />,
          label: <>
            <Tooltip title="Access the user guide for navigation tips and system usage instructions." placement={toolTipPosition}>
              <span > User Guide</span>
            </Tooltip>
          </>,
        },
        {
          key: '/provider/feedBack',
          icon: <RiFeedbackLine />,
          label: <>
            <Tooltip title="Help us improve by sharing your feedback and recommending changes" placement={toolTipPosition}>
              <span > Feedback & Suggestions</span>
            </Tooltip>
          </>,
        },
      ]
    }
  ], [project, role]);



  useEffect(() => {
    if (avatarUrl !== "") {
      setFileList([
        {
          uid: '-1',
          name: 'profile.png',
          status: 'done',
          url: avatarUrl,
        },
      ]);
    } else {
      setFileList([]);
    }
  }, [avatarUrl]);


  useEffect(() => {
    // Extract the base path for user-directory-related paths
    if (location?.pathname?.includes("/user-directory")) {
      // For user-directory tabs, always highlight the base path
      setSelectedKey('/provider/user-directory');
    }
    else if (location?.pathname?.includes("/repository-directory")) {
      setSelectedKey('/provider/repository-directory');

    }
    else {
      // For other paths, directly use the full location.pathname
      setSelectedKey(location.pathname);
    }
  }, [location.pathname]);



  // useEffect(() => {

  //   if (userProject) {
  //     const findProject = _.find(projectList, {id: userProject });
  //     setProjectName(findProject?.name);
  //   }
  //   else {
  //     navigate("/")

  //   }
  // }, [navigate, projectList, userProject]);

  const getBreadcrumbs = useCallback(() => {
    const pathnames = location.pathname.split('/').filter(x => x);
    const breadcrumbs = [];
    let breadcrumbPath = '';

    pathnames.forEach((pathname, index) => {
      breadcrumbPath += `/${pathname}`;
      const breadcrumb = navigationItems.find(item => item.key === breadcrumbPath);
      if (breadcrumb) {
        breadcrumbs.push({
          key: breadcrumbPath,
          title: (
            <Link to={breadcrumbPath} style={{ display: 'flex', alignItems: 'center', paddingRight : '0px' }}>
              {breadcrumb.icon}
              {breadcrumb.label && <span style={{ marginLeft: 8 }}>{breadcrumb.label}</span>}
            </Link>
          )
        });

      } else {
        // Handle submenus
        const findInSubmenus = (items) => {
          for (const item of items) {
            if (item.children) {
              const childItem = findInSubmenus(item.children);
              if (childItem) return childItem;
            }
            if (item.key === breadcrumbPath) {
              return item;
            }
          }
          return null;
        };

        const submenuBreadcrumb = findInSubmenus(navigationItems);
        if (submenuBreadcrumb) {
          breadcrumbs.push({
            key: breadcrumbPath,
            title: (
              <Link to={breadcrumbPath} style={{ display: 'flex', alignItems: 'center' }}>
                {submenuBreadcrumb.icon}
                {submenuBreadcrumb.label && <span style={{ marginLeft: 8 }}>{submenuBreadcrumb.label}</span>}
              </Link>
            )
          });
        }
      }
      if (pathname === 'user-directory') {
        breadcrumbs.push({
          key: `tab-${index}`,
          title: tabName
        });
      }
      if (pathname === 'repository-directory') {
        breadcrumbs.push({
          key: `tab-${index}`,
          title: reportTabName
        });
      }

    });

    return breadcrumbs;
  }, [location.pathname, navigationItems, reportTabName, tabName]);


  useEffect(() => {
    let loadOneTime = true;
    if (loadOneTime) {
      const breadcrumbResult = getBreadcrumbs();
      if (Array.isArray(breadcrumbResult) && breadcrumbResult.length > 0) {
        setBreadcrumbItems(breadcrumbResult);
      }
    }

    return () => {
      loadOneTime = false;
    }
  }, [getBreadcrumbs])

  const menuItems = [
    {
      key: '1',
      label: <div className="shadow bg-white p-2 text-center rounded-xl font-plus-jakarta-sans text-[#458FF6] text-xs font-semibold">Edit Profile </div>,
      onClick: () => navigate("/provider/2fa"),
    },
    {
      key: '2',
      label: <div className="bg-[#458FF6] text-white rounded-xl p-2 text-center text-xs font-plus-jakarta-sans font-semibold">Log Out</div>,
      onClick: () => {
        SetLogOut(true)
      },
    },

  ];

  const handleCancel = () => {
    SetLogOut(false)
  }

  const handleLogOutFinish = () => {
    dispatch(clearUserDetails());
    navigate("/provider");
  }

  const handlePreview = async (file) => {
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  // const handleMouseEnter = () => setIconVisible(true)
  // const handleMouseLeave = () => setIconVisible(false)

  const onMenuClick = ({ key }) => {
    navigate(key); // Navigate to the path based on menu item key
  };





  return (
    <Layout style={layoutStyle}>
      <Sider
        collapsed={collapsed}
        onCollapse={toggleCollapsed}
        breakpoint="md"
        collapsedWidth={isMobile ? 0 : 80}
        width={250}
        style={siderStyle}
      >
        <div className="flex items-center justify-center ">
          <img
            className={`w-9 h-auto mx-1`}
            src={logo}
            alt="Logo"
          />
          {
            !collapsed && (
              <div className="text-nowrap font-poppins font-bold mx-1">
                MYWWS
              </div>
            )
          }
        </div>
        <Menu
          className="bg-white mt-3"
          selectedKeys={[selectedKey]}
          mode="inline"
          items={navigationItems}
          onClick={onMenuClick} // Handle navigation on click
        />

      </Sider>
      <Layout>
        <Header style={headerStyle}>
          <div className="flex items-center">
            {collapsed ? (
              <RightOutlined
                className=" text-black transform rotate-180 transition-transform mt-3 "
                onClick={() => setCollapsed(false)}
                style={{ fontSize: '100%', fontWeight: 'medium' }}
              />

            ) : (
              <RightOutlined
                className=" text-black transform transition-transform  mt-3"
                onClick={() => setCollapsed(true)}
                style={{ fontSize: '100%', fontWeight: 'medium' }}
              />

            )}
          </div>
          <div className="flex items-center">
            {
              projectName ? (
                <span className="mr-6 text-sm md:text-xs lg:text-xs font-bold font-poppins">{projectName}</span>
              ) : null
            }
            <Notification open={open} setOpen={setOpen} />
            <div
              onMouseEnter={() => setIconVisible(true)}
              onMouseLeave={() => setIconVisible(false)}
            >

              <Dropdown
                menu={{
                  items: menuItems,
                }}
              >
                <Button
                  style={{ display: 'flex' }}
                  type="link"
                >
                  <Avatar

                    style={{ display: "flex", padding: "0px" }}
                    size="small">
                    {fileList?.length > 0 ? (
                      <Upload
                        listType="picture-circle"
                        fileList={fileList}
                        showUploadList={{ showRemoveIcon: false }}
                        onPreview={handlePreview}
                      // style={{ display: 'flex', alignItems: 'center' }}
                      />
                    ) : (
                      firstName?.charAt(0)?.toUpperCase()
                    )}
                  </Avatar>
                  <div className="ml-2 flex flex-col  ">
                    {firstName && lastName !== undefined ? (<span className="text-xs font-bold font-poppins">{`${firstName} ${lastName}`}</span>) : "Loading..."}
                    <span className="text-xs font-poppins text-gray-500">{getMappedRoleName(role)}</span>
                  </div>
                  {iconVisible ? (
                    <DownOutlined
                      className="ml-2 text-black transform rotate-180 transition-transform "
                      style={{ fontSize: '70%', fontWeight: 'medium' }}
                    />
                  ) : (
                    <DownOutlined
                      className="ml-2 text-black transform transition-transform "
                      style={{ fontSize: '70%', fontWeight: 'medium' }}
                    />
                  )}

                </Button>
              </Dropdown>
            </div>
          </div>

        </Header>
        <Content style={contentStyle}>
          {
            location.pathname !== "/provider/dashboard" && (
              < Breadcrumb
                className="font-bold text-gray-800 text-base"
                items={breadcrumbItems}
              />
            )
          }
          {/* {location.pathname !== "/provider/dashBoard" && <Breadcrumb style={{ margin: '10px 0' }} className="font-bold text-gray-800 text-base">
            {getBreadcrumbs()}
          </Breadcrumb>} */}
          <InactivityProvider>
            <Suspense fallback={
              <div className="w-full h-[50%] flex justify-center items-center">
                <div className="font-poppins text-center text-black text-opacity-100">
                  <span className='font-poppins text-md text-black animate-fadeIn inline'>
                    Loading<p className="text-4xl font-semibold animate-pulse inline"> . . .</p>
                  </span>
                </div>
              </div>
            }>
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/dashBoard" element={
                  <Dashboard
                    providerList={providerList}
                  />} />
                <Route path="user-directory/*" element={<ProtectorRoute allowedRoles={['is']}> <ProviderDirectoryTabs
                  isProvider={true}
                  setTabName={setTabName} />
                </ProtectorRoute>} />
                <Route path="repository-directory/*" element={<RepositoryDirectoryTabs setReportTabName={setReportTabName} />} />
                <Route path="/2fa" element={<Profile userName={firstName} />} />
                <Route path="/document" element={<Document />} />
                <Route path="/projectDetails/:id" element={<Batch />} />
                <Route path="/chart" element={<Chart />} />
                <Route path="/exclusionList" element={<Exclusion />} />
                <Route path="exclusionList/view/:id" element={<ExclusionTableView />} />
                <Route path="/paymentCalculator" element={<DMEPaymentCalculator />} />
                <Route path="/npiValidation" element={<PhysicianNpiLookup />} />
                <Route path="/physicianDetails/:id" element={<PhysicianDetails />} />
                <Route path="recentHistory/" element={<RecentHistory setEditPatientViewStatus={setEditPatientViewStatus} setEditPatientData={setEditPatientData} />} />
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/notification" element={<NotificationCenter />} />
                <Route path="/faq" element={<FAQComponent />} />
                <Route path="/glossary" element={<GlossaryTermsComponent />} />
                <Route path="/userGuide" element={<UserGuide />} />
                <Route path="/feedBack" element={<FeedbackRedirect />} />
              </Routes>
            </Suspense>
          </InactivityProvider>
        </Content>
        <AntFooter />
      </Layout >

      {
        logOut &&
        <ModalBox
          open={logOut}
          onCancel={handleCancel}
          footer={null}
          content={
            <div className="flex justify-center flex-col ml-[10%] p-3">
              <span className="font-poppins font-semibold text-start text-black ">Just confirming !</span>
              <span className="font-poppins text-start text-black">are you sure you want to end your session?</span>

            </div>
          }
          onCancelTitle={"Cancel"}
          onSubmitTitle={"Confirm"}
          onSubmit={handleLogOutFinish}

        >
        </ModalBox>
      }
      {
        previewImage && (
          <Image
            wrapperStyle={{
              display: 'none',
            }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(''),
            }}
            src={previewImage}
          />
        )
      }
      {
        editPatientViewStatus && (
          <UpdatePatient
            editPatientViewStatus={editPatientViewStatus}
            setEditPatientViewStatus={setEditPatientViewStatus}
            editPatientData={editPatientData}
            setEditPatientData={setEditPatientData}
            chartList={chartList}
          />
        )
      }
    </Layout >
  );
};

export default ProviderIndex;
