import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { notification } from 'antd';
import _ from "lodash";

function ProviderProtectedRoute({ children, allowedRoles }) {
  const { providerList } = useSelector((state) => state.provider);
  const userId = useSelector((state) => state.userDetails.userId);
  const findUser = _.find(providerList, { id: userId });
  let userRole = findUser?.access;

  useEffect(() => {
    if (userRole && !allowedRoles?.includes(userRole)) {
      notification.warning({
        message: 'Access Denied',
        description: 'You do not have the required permissions to access this page.',
        placement: 'topRight',
      });
    }
  }, [userRole, allowedRoles]);

  if (!userRole) {
    return children;
  }

  if (allowedRoles?.includes(userRole)) {
    return children;
  }

  return <Navigate to="/" />;
}

export default ProviderProtectedRoute;
