import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Table, Input, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createOrUpdateRecentHistoryApi } from '../../redux/recentHistory/recentHistorySlice';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import usePaginatedRecentHistory from '../../pagination/usePaginatedRecentHistory ';
import { FaArrowLeft } from 'react-icons/fa';


export const RecentHistory = ({ setEditPatientData, setEditPatientViewStatus }) => {

  const userId = useSelector((state) => state.userDetails.userId);
  const projectId = useSelector((state) => state.userDetails.project);
  const [filteredPatientName, setFilteredPatientName] = useState(null);
  const [filteredVfStatus, setFilteredVfStatus] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  // const [checkedRowKeys, setCheckedRowKeys] = useState([]);
  const dispatch = useDispatch();
  const { history, recentHistoryCurrentPage, recentHistoryPageSize, totalHistory, setRecentHistoryCurrentPage, setRecentHistoryPageSize, setSearchTerm } = usePaginatedRecentHistory(1, 10, "", userId, projectId);
  const searchInput = useRef(null);
  const navigate = useNavigate();

  const handlePatientClick = (id) => {
    if (id) {
      const getContact = _.filter(history, (item) => item.chartId === id);
      if (getContact.length > 0) {
        setEditPatientViewStatus(true);
        setEditPatientData(getContact[0].chartDetails);
      }
    }
  };

  const handleCreateOrUpdate = (historyData) => {
    dispatch(createOrUpdateRecentHistoryApi(historyData));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
    setSearchTerm(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
    setFilteredPatientName(null);
    setFilteredVfStatus(null);
    setSearchTerm('');
  };

  const handleBack = () => {
    navigate("/provider/dashBoard")
  };

  const getFilteredCharts = () => {
    if (!Array.isArray(history)) {
      console.error('history is not an array:', history);
      return [];
    }
    return history.filter((item) => (
      (!filteredPatientName || item?.patientName === filteredPatientName) &&
      (!filteredVfStatus || item?.vfCategory === filteredVfStatus)
    ));
  };




  const filteredCharts = getFilteredCharts();


  const getUniqueValues = (key, data = [], nestedKey = null) => {
    const values = data.map((item) => nestedKey ? item[nestedKey][key] : item[key]);
    return Array.from(new Set(values));
  };
  const uniqueNames = getUniqueValues('patientName', filteredCharts || []);
  const uniqueVfStatus = getUniqueValues('vfCategory', filteredCharts || []);
  const uniqueDob = getUniqueValues('dob', filteredCharts || []);
  const uniquePatientPrimaryInsurance = getUniqueValues('patientPrimaryInsurance', filteredCharts || []);
  const uniquePatientPrimaryInsuranceName = getUniqueValues('primaryInsurance', filteredCharts || []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            setSearchTerm(e.target.value || '');
          }
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const data = dataIndex.split('.').reduce((o, i) => (o ? o[i] : ''), record); // Handle nested fields
      return data ? data.toString().toLowerCase().includes(value.toLowerCase()) : false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Patient Name',
      dataIndex: ['chartDetails', 'patientName'],
      key: 'patientName',
      width: 200,
      responsive: ['xs', 'sm', 'md', 'lg'],
      filters: uniqueNames.map((name) => ({
        text: name,
        value: name,
      })),
      onFilter: (value, record) => record.patientName === value,
      ...getColumnSearchProps('chartDetails.patientName'),
      render: (text, record) => (
        <Button
          type='button'
          className='hover:text-blue-bm'
          onClick={(e) => {
            e.preventDefault();
            handlePatientClick(record.chartId);
            let chartId = record.chartId;
            handleCreateOrUpdate({ userId, chartId, projectId, isActive: true, timeZone: 'UTC' })
          }}
        >

          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        </Button>
      ),
    },
    {
      title: 'DOB',
      dataIndex: ['chartDetails', 'dob'],
      key: 'dob',
      width: 100,
      responsive: ['xs', 'sm', 'md', 'lg'],
      filters: uniqueDob.map((status) => ({
        text: status,
        value: status,
      })),
      onFilter: (value, record) => record.dob === value,
      ...getColumnSearchProps('chartDetails.dob'),
    },
    {
      title: 'Primary Insurance Number',
      dataIndex: ['chartDetails', 'patientPrimaryInsurance'],
      key: 'patientPrimaryInsurance',
      width: 100,
      responsive: ['xs', 'sm', 'md', 'lg'],
      filters: uniquePatientPrimaryInsurance.map((status) => ({
        text: status,
        value: status,
      })),
      onFilter: (value, record) => record.patientPrimaryInsurance === value,
      ...getColumnSearchProps('chartDetails.patientPrimaryInsurance'),
    },
    {
      title: 'Primary Insurance',
      dataIndex: ['chartDetails', 'primaryInsurance'],
      key: 'primaryInsurance',
      width: 100,
      responsive: ['xs', 'sm', 'md', 'lg'],
      filters: uniquePatientPrimaryInsuranceName.map((status) => ({
        text: status,
        value: status,
      })),
      onFilter: (value, record) => record.primaryInsurance === value,
      ...getColumnSearchProps('chartDetails.primaryInsurance'),
    },
    {
      title: 'Chart Status',
      dataIndex: ['chartDetails', 'vfCategory'],
      key: 'vfCategory',
      width: 100,
      responsive: ['xs', 'sm', 'md', 'lg'],
      filters: uniqueVfStatus.map((status) => ({
        text: status,
        value: status,
      })),
      onFilter: (value, record) => record.vfCategory === value,
      ...getColumnSearchProps('chartDetails.vfCategory'),
    },
    {
      title: 'Last Seen',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 100,
      responsive: ['xs', 'sm', 'md', 'lg'],
      render: (text) => new Date(text).toLocaleString(),
    },
  ];

  const onChange = (pagination, filters) => {
    setFilteredPatientName(filters.patientName ? filters.patientName[0] : null);
    setFilteredVfStatus(filters.vfCategory ? filters.vfCategory[0] : null);
    setRecentHistoryCurrentPage(pagination.current);
    setRecentHistoryPageSize(pagination.pageSize);
  };

  // const onCheckChange = (newCheckedRowKeys) => {
  //   setCheckedRowKeys(newCheckedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys: checkedRowKeys,
  //   onChange: onCheckChange,
  // };

  return (
    <div className="w-full h-full px-2">
      <Row gutter={[16, 16]} justify="start" align="middle" className='mt-2'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Button
            type='button'
            onClick={handleBack}
            className="p-2 text-blue-600 hover:text-blue-800"
          >
            <FaArrowLeft />
          </Button>
        </Col>
      </Row>
      <div className="mt-2 overflow-auto max-h-[65vh] no-scrollbar">
        <div className="min-w-[600px] sm:min-w-full">
          <Row gutter={[16, 16]} justify="center" align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="border shadow-md rounded-md mb-3">
                <div className="custom-ant-table-design">
                  <Table
                    columns={columns}
                    dataSource={history || []}
                    size='small'
                    rowHoverable={false}
                    rowKey={(record) => record.id || record._id}
                    // rowSelection={rowSelection}
                    pagination={{
                      current: recentHistoryCurrentPage,
                      pageSize: recentHistoryPageSize,
                      total: totalHistory,
                      showSizeChanger: true,
                    }}
                    onChange={onChange}
                    scroll={{ y: 430 }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>

  );
};

