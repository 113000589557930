import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit';
import {
  getProjectsApi,
  getProjectsPaginationApi,
  createProjectApi,
  updateProjectApi,
  deleteProjectApi
} from './projectService';
import { setLoading } from '../loading/loadingSlice';

const initialState = {
  projectList: [],
  projectListPagination: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
  },
  loading: 'idle',
  currentRequestId: undefined,
  listenerActive: false, // Flag to control the listener
  error: null,
}


export const projectListenerMiddleware = createListenerMiddleware();

let listenerUnsubscribe; // Variable to hold the unsubscribe function for the listener

// Listener for API actions (create, update, delete)
projectListenerMiddleware.startListening({
  predicate: (action) =>
    [
      createProjectApi.fulfilled.type,
      updateProjectApi.fulfilled.type,
      deleteProjectApi.fulfilled.type,
    ].includes(action.type),
  effect: async (_, { dispatch }) => {
    try {
      dispatch(setLoading(true));
        await dispatch(getProjectsApi()).unwrap();
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  },
});



// Function to start the listener
const startProjectListener = () => {
  listenerUnsubscribe = projectListenerMiddleware.startListening({
    predicate: (_, currentState) => {
      const { projectList, listenerActive } = currentState.project;
      const isListEmpty = projectList.length === 0;

      if (!isListEmpty) {
        return false; // Stop evaluating the predicate
      }

      if (listenerActive) {
        return false; // Prevent redundant activation
      }

      return isListEmpty && !listenerActive; // Trigger only if projectList is empty
    },
    effect: async (_, { dispatch, getState, unsubscribe }) => {
      dispatch(setListenerActive(true)); // Mark listener as active
      try {
        dispatch(setLoading(true));

        // Fetch the project list
        await dispatch(getProjectsApi()).unwrap();

        const { projectList } = getState().project;
        if (projectList.length > 0) {
          dispatch(setListenerActive(false)); // Deactivate listener
          unsubscribe(); // Stop the listener
        }
      } catch (error) {
        console.error('Error during listener execution:', error);
        dispatch(setListenerActive(false)); // Reset listener flag in case of failure
      } finally {
        dispatch(setLoading(false));
      }
    },
  });
};

// Start the listener initially
startProjectListener();

// Restart the listener when needed
export const restartProjectListener = () => {
  if (listenerUnsubscribe) {
    listenerUnsubscribe(); // Unsubscribe the existing listener
  }
  startProjectListener(); // Start a new listener
};



export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // add your non-async reducers here
    setListenerActive(state, action) {
      state.listenerActive = action.payload;
    },
    logout: state => {
      state.projectList = [];
      state.projectListPagination = [];
      state.loading = 'idle';
      state.currentRequestId = undefined;
      state.error = null;
      state.pagination = {
        currentPage: 1,
        pageSize: 10,
        totalPages: 0,
        totalRecords: 0,
      };
      state.listenerActive = false; // Ensure listener is deactivated on logout
    }
  },
  extraReducers: (builder) => {
    // extraReducers handles asynchronous requests, which is our main focus.
    // GET
    builder
      .addCase(getProjectsApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getProjectsApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.projectList.length = 0;
          state.projectList.push(...action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(getProjectsApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    //get project by pagination

    builder
      .addCase(getProjectsPaginationApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getProjectsPaginationApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.projectListPagination = action.payload.projects;
          state.pagination = action.payload.pagination;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getProjectsPaginationApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.currentRequestId = undefined;
          state.error = action.payload?.errorMessage || action.error.message;
        }
      });


    // POST
    builder
      .addCase(createProjectApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(createProjectApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.projectList.unshift(action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(createProjectApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // PUT
    builder
      .addCase(updateProjectApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(updateProjectApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          const updateItem = action.payload;
          const index = state.projectList.findIndex((item) => item.id === updateItem.id);
          if (index > -1) {
            state.projectList[index] = updateItem;
          }
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(updateProjectApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // DELETE
    builder
      .addCase(deleteProjectApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteProjectApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          const index = state.projectList.findIndex((item) => item.id === action.payload);
          if (index > -1) {
            state.projectList.splice(index, 1);
          }
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteProjectApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })
  }
})

// Action creators are generated for each case reducer function
export const { logout, setListenerActive } = projectSlice.actions;

export default projectSlice.reducer;