import React, { memo } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { axiosErrorHandling, getPathNameByRole, getPathNameByNavigationLabel } from '../../utils/utility';

const InfoCard = ({ icon, label, tooltipText = '', buttonText }) => {
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.userDetails);

  const onClickLabel = async (event, label) => {
    event.preventDefault();
    try {
      const roleResponse = await getPathNameByRole(role);
      const navigationResponse = await getPathNameByNavigationLabel(label);
      const navigationURL = `/${roleResponse}/${navigationResponse}`;

      if (navigationURL) {
        navigate(navigationURL);
      } else {
        navigate('/');
      }
    } catch (error) {
      axiosErrorHandling(error);
    }
  }

  return (
    <>
      <Tooltip title={tooltipText}>
        <div className="w-[95%] h-[27vh] flex flex-col items-center justify-between rounded-[1.5vw] bg-blue-50 p-4"
        
        style={{
          boxShadow: '-8px 8px 15px rgba(0, 0, 0, 0.2)', // Shadow bottom and left
        }}

        >
          {/* Icon Section */}
          <div className="w-16 h-16 rounded-full flex items-center justify-center"
            style={{ backgroundColor: '#D4E7FF' }}
          >
            <span className="text-3xl">
              {icon}
            </span>
          </div>

          {/* Label Button */}
          <button
            type='button'
            className="w-full bg-blue-bm text-white font-medium py-2 px-4 rounded-full text-xs relative border-0 shadow-none cursor-pointer whitespace-nowrap hover:bg-blue-400 transition-all duration-100"
            onClick={(e) => onClickLabel(e, label)}
          >
            {buttonText}
          </button>
        </div>
      </Tooltip>
    </>
  )
}

export default memo(InfoCard);