import { createSlice } from '@reduxjs/toolkit';
import { createOrUpdateRecentHistoryApi, getRecentHistoryApi } from './recentHistoryService';

// Initial state 
const initialState = {
    data: [],      
    loading: 'idle',
    error: null,  
};
  
  const recentHistorySliceReducer = createSlice({
    name: 'recentHistory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      // Fetch recent history
      builder
        .addCase(getRecentHistoryApi.pending, (state) => {
            if (state.loading === 'idle') {
                state.loading = 'pending';
                state.error = null;
            }
        })
        .addCase(getRecentHistoryApi.fulfilled, (state, action) => {
            if (state.loading === 'pending') {
                state.loading = 'idle';
                // if (Array.isArray(action.payload)) {
                //     state.data = action.payload;
                // } else if (action.payload.record) {
                //     // If payload has a single record, wrap it in an array
                //     state.data = [action.payload.record];
                // } else {
                //     state.data = []; // Or handle the case where payload is unexpected
                //     console.error("Unexpected payload format:", action.payload);
                // }
                state.data = action.payload; 
            }
        })
        .addCase(getRecentHistoryApi.rejected, (state, action) => {
            if (state.loading === 'pending') {
                state.loading = 'idle';
                state.error = action.payload || 'Failed to fetch recent history';
            }
        });
  
      // Create or update recent history
      builder
        .addCase(createOrUpdateRecentHistoryApi.pending, (state) => {
            if (state.loading === 'idle') {
                state.loading = 'pending';
                state.error = null;
            }
        })
        .addCase(createOrUpdateRecentHistoryApi.fulfilled, (state, action) => {
            if (state.loading === 'pending') {
                state.loading = 'idle';
                if (Array.isArray(action.payload)) {
                    state.data = action.payload;
                } else {
                    state.data = [];
                }
                // Update or add new history record
                const updatedRecord =  action.payload.record || action.payload;
                if (Array.isArray(updatedRecord)) {
                    state.data = updatedRecord;
                } else {
                    const index = state.data.findIndex((item) => item.chartId === updatedRecord.chartId);
                    if (index >= 0) {
                        state.data[index] = updatedRecord; // Update existing record
                    } else {
                        state.data.push(updatedRecord); // Add new record
                    }
                }
            }
        })
        .addCase(createOrUpdateRecentHistoryApi.rejected, (state, action) => {
            if (state.loading === 'pending') {
                state.loading = 'idle';
                state.error = action.payload || 'Failed to create or update history';
            }
        });
    },
  });
  
  export const selectRecentHistoryData = (state) => state.recentHistory.data;
  export const selectRecentHistoryLoading = (state) => state.recentHistory.loading;
  export const selectRecentHistoryError = (state) => state.recentHistory.error;  
  
  export { getRecentHistoryApi, createOrUpdateRecentHistoryApi };

  export default recentHistorySliceReducer.reducer;