import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Popover, Spin, Switch, Tag, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { setLoading } from '../../redux/loading/loadingSlice';
import { useNavigate } from 'react-router-dom';
import NotificationWithRed from "../../assets/svg_images/Notifications with dot.svg";
import NotificationIcon from "../../assets/svg_images/notification.svg";


dayjs.extend(relativeTime);
dayjs.locale('en');

const color = "#458ff6"

const countUnreadMessages = (notificationList, userId) => {

  let finalValue = notificationList?.filter(notification =>
    notification?.readStatus &&
    notification?.readStatus?.userId === userId &&
    !notification?.readStatus?.isRead
  )?.length;
  return finalValue
};

const Notification = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notificationList } = useSelector((state) => state.notification);
  const role = useSelector((state) => state.userDetails.role);
  const userId = useSelector((state) => state.userDetails.userId);
  const userProject = useSelector((state) => state.userDetails.project);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Changed from ref to state
  const [notificationListCopy, setNotificationListCopy] = useState([]);
  const switchToggler = useRef(false);
  const SortByStatus = useRef(0);
  const unReadCount = useRef(0);
  const isRequestInProgressRef = useRef(false); // Prevent simultaneous calls



  useEffect(() => {
    const fetchNotifications = async () => {
      if (isRequestInProgressRef.current) {
        return;
      }

      isRequestInProgressRef.current = true; // Set flag to indicate a call is in progress

      try {
        dispatch(setLoading(true));

        // Dynamically import the API service

        // Make the API call
        if (userProject && role && userId) {
          const { getTodayNotificationsApi } = await import('../../redux/notification/notificationService');

          await dispatch(getTodayNotificationsApi({ userProject, role, userId })).unwrap();
        }
        // await dispatch(getTodayNotificationsApi({ userProject, role, userId })).unwrap();


      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      } finally {
        setIsLoading(false)
        isRequestInProgressRef.current = false; // Reset the flag
        dispatch(setLoading(false));
      }
    };

    fetchNotifications();
  }, [dispatch, role, userId, userProject]);

  useEffect(() => {
    const testing = async () => {
      if (switchToggler.current) {
        const { default: _ } = await import('lodash');
        const filteredArray = _.filter(notificationList, function (o) {
          return o?.readStatus && !o?.readStatus?.isRead;
        });
        setNotificationListCopy(filteredArray);
      } else {
        setNotificationListCopy(notificationList);
      }
      unReadCount.current = countUnreadMessages(notificationList, userId);
      const unreadExists = notificationList?.some(notification =>
        notification?.readStatus &&
        notification?.readStatus?.userId === userId &&
        !notification?.readStatus?.isRead
      );

      setHasUnreadNotifications(unreadExists);
    }
    testing()
  }, [notificationList, userId]);

  const handleNotificationClick = useCallback(
    async ({ id, readId }) => {

      const updatedList = notificationListCopy?.map((notification) => {
        if (notification?._id === id) {
          return {
            ...notification,
            readStatus: {
              ...notification?.readStatus,
              isRead: true,
            },
          };
        }
        return notification;
      });

      setNotificationListCopy(updatedList)
      // switchToggler.current === true ? handleChecked() : setNotificationListCopy(updatedList);

      try {
        const { default: _ } = await import('lodash');

        // Find the notification in the list
        let result = _.find(notificationListCopy, { _id: id });

        // Example condition to update read status
        if (result && !result.readStatus?.isRead) {
          // Uncomment and use if needed to mark as read:
          const { markNotificationAsReadApi } = await import('../../redux/notification/notificationService');
          await dispatch(markNotificationAsReadApi({ notificationId: id, readStatusId: result?.readStatus?._id, userId: userId, role: role })).unwrap();

          // setNotifications((prevNotifications) =>
          //   prevNotifications.map((notification) =>
          //     notification?.id === id ? { ...notification, isRead: true } : notification
          //   )
          // );

        }
      } catch (error) {
        console.error('Failed to mark notification as read:', error);
      }
    }, [dispatch, notificationListCopy, role, userId]);

  const handleNotificationClickWithId = useCallback((id) => {
    return () => {
      handleNotificationClick(id);
    };
  }, [handleNotificationClick]);

  const tags = [
    {
      key: 0,
      name: "all",
      htmlContent: <Tooltip title="View all notifications" placement="top">
        <Tag className='rounded-2xl px-3 font-poppins text-md cursor-pointer' color={SortByStatus?.current === 0 ? color : null}>All</Tag>
      </Tooltip>
    },
    {
      key: 1,
      name: "byUser",
      htmlContent: <Tooltip title="View notifications related to user activities" placement="top"> <Tag className='rounded-2xl px-3 font-poppins text-md cursor-pointer' color={SortByStatus?.current === 1 ? color : null}>By User</Tag>
      </Tooltip>
    },
    {
      key: 2,
      name: "bySystem",
      htmlContent: <Tooltip title="View notifications generated by the system" placement="top"> <Tag className='rounded-2xl px-3 font-poppins text-md cursor-pointer' color={SortByStatus?.current === 2 ? color : null}>By System</Tag>
      </Tooltip>

    },

  ]

  const handleFilter = useCallback(async (selectedKey, name) => {
    SortByStatus.current = selectedKey
    // Implement your filter logic here based on the selected key
    if (selectedKey === 0) {

      setNotificationListCopy(notificationList);

    } else if (selectedKey === 1) {

      const userNotifications = notificationList?.filter(notification =>
        notification?.type === 'batchUpload' || notification?.type === 'Workflow' || notification?.type === 'User Account'
      );
      setNotificationListCopy(userNotifications);
      // Handle "By User" filter logic
      // setNotifications(notificationList.filter(notification => notification.type === 'user'));
    } else if (selectedKey === 2) {
      const systemNotifications = notificationList?.filter(notification => notification?.type === 'System');
      setNotificationListCopy(systemNotifications);
      // Handle "By System" filter logic
      // setNotifications(notificationList.filter(notification => notification.type === 'system'));
    }
  }, [notificationList]);

  const handleSwitchChange = useCallback(async (checked) => {


    if (checked) {
      const { default: _ } = await import('lodash');
      switchToggler.current = true
      // Filter notifications where readStatus.isRead is false
      const filteredArray = _.filter(notificationListCopy, function (o) {
        return o?.readStatus && !o?.readStatus?.isRead;
      });
      setNotificationListCopy(filteredArray);
    }
    else {
      switchToggler.current = false
      setNotificationListCopy(notificationList);
    }
  }, [notificationList, notificationListCopy]);

  const handleShowAll = useCallback(() => {
    setOpen((prev) => !prev); // Toggle the Popover state
    switch (role) {
      case "ADMIN":
      case "GUEST":
        navigate('/admin/notification');
        break;
      case "PROVIDER":
        navigate('/provider/notification');
        break;
      case "USER":
        navigate('/provider/notification');
        break;
      case "SUPER ADMIN":
        navigate('/super-admin/notification');
        break;
      case "VERIFICATION CALLER":
      case "VERIFICATION":
      case "AUDIT":
      case "AUDIT CALLER":
      case "CODING":

        navigate('/users/notification');
        break;

      default:
        navigate('/');
        break;
    }
    // navigate("/Notification")
  }, [navigate, role, setOpen]);


  const notificationContent = (

    <div className='w-[100%] relative'>
      {isLoading && (
        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-70 backdrop-blur-sm z-10">
          <Spin size="small" />
        </div>
      )}
      <div className='flex justify-between items-center p-2'>
        <div className="flex items-center">
          <img
            src={NotificationIcon}
            alt="notificationIcon"
            className="w-7 h-7 mr-[0.031rem]"
          />
          <span className='font-poppins text-md'>Notifications</span>
        </div>
        <div className="flex items-center">
          <span className='text-sm'>Show only unread </span>
          <span className='text-sm ml-1 '>({unReadCount?.current})</span>
          <Switch className='ml-2 custom-switch' value={switchToggler?.current} size="small" onChange={handleSwitchChange} />
        </div>
      </div>
      <hr className='border-[#5e5e5e] opacity-5 mb-[2%]' />
      {
        role !== "PROVIDER" && role !== "USER" ? <div className='flex'>
          <span className='font-poppins mr-[2%] text-md'>Sort By:</span>
          {
            tags?.map(({ name, htmlContent, key }) => (
              <div key={key} onClick={() => handleFilter(key, name)}>
                {htmlContent}
              </div>
            ))
          }
        </div> : <div className="font-poppins mr-[2%]">View notifications generated by the system</div>
      }

      <div className='h-[2%] bg-[#ececec] mt-[2%] p-2 rounded-sm mb-[2%]'>
        <span className='font-poppins'>Today</span>
      </div>
      <div className='max-h-[48vh] h-[71vh] overflow-y-auto '
      // style={{
      //   "scrollbarWidth": "thin",
      //   "scrollbarColor": "#D9D9D9 #ffffff",
      // }}
      >


        {notificationListCopy?.length > 0 ? (
          notificationListCopy?.map((notification, index) => {

            return (
              <ul
                key={notification?._id}

                className={` list-disc pl-6 font-poppins  rounded-md ${notification?.readStatus?.isRead ? "cursor-default" : "cursor-pointer"}  w-full 
                ${notification?.readStatus?.isRead ? "text-gray-400" : null}
                  hover:bg-[#fafcff] hover:bg-opacity-100
                 `}
                onClick={handleNotificationClickWithId({ id: notification?._id, readId: notification?.readStatus?._id })} // Use the pre-bound function here
              >
                <li className='py-2 font-poppins mb-[1%] ' key={notification?._id}>
                  <span
                    className={`${notification?.readStatus?.isRead ? "text-gray-800" : "font-bold text-black text-md"} `}
                  >{notification?.title}</span>
                  <p>
                    <span className="text-sm">
                      {notification?.message?.split(/(\s|\d+)/)?.map((part, index) => {
                        if (/^\d+\s*charts$/?.test(part?.trim())) {
                          return <span key={index} className="font-bold">{part}</span>;
                        }
                        if (part?.trim()?.toUpperCase() === 'VERIFICATION.') {
                          return <span key={index} className={`${notification?.readStatus?.isRead ? "text-gray-800" : "text-green-600"} font-medium`} > {part}</span>;
                        }
                        if (/\b(deleted)\b/i.test(part?.trim())) {
                          return <span key={index} className={`${notification?.readStatus?.isRead ? "text-gray-800" : "text-red-600 font-medium"}`} > {part}</span>;
                        }
                        if (/\d/?.test(part)) {
                          return <span key={index} className={`${notification?.readStatus?.isRead ? "text-gray-800" : "text-[#3a89f7]"} `} > {part}</span>;
                        }
                        return <span key={index} className="text-gray-800">{part}</span>;
                      })}
                    </span>
                    <br></br>
                    <span className="text-xs text-slate-600 text-end">
                      {/* {dayjs(notification?.createdAt).fromNow()} */}
                      {dayjs(notification?.createdAt).locale('en').fromNow()}
                    </span>
                  </p>
                </li>
              </ul>
            )
          })
        ) : (
          <p className="text-center text-gray-500">No notifications</p>
        )}
      </div>
      <div className='h-[2%]  mt-[2%]  text-center p-2 rounded-sm '>
        <Tooltip title="Click to view all notifications" placement="top">
          <span className='font-poppins border text-blue-400 underline  hover:border-l-royalblue-100 cursor-pointer' onClick={handleShowAll}>Show All</span>
        </Tooltip>
      </div>
    </div >

  );

  // const handleChangePopOver = useCallback(() => {

  //   switchToggler.current = false
  //   setOpen(prev => !prev)
  // }, [setOpen])


  const handleChangePopOver = useCallback(async () => {
    switchToggler.current = false; // Reset the toggle state
    setOpen((prev) => !prev); // Toggle the Popover state

    if (!open) {
      // Call the API only when the Popover is opened
      try {
        // dispatch(setLoading(true)); // Start loading indicator
        setIsLoading(true)
        if (userProject && role && userId) {
          const { getTodayNotificationsApi } = await import('../../redux/notification/notificationService');

          await dispatch(getTodayNotificationsApi({ userProject, role, userId })).unwrap();
        }


        setIsLoading(false)

        // dispatch(setLoading(false)); // Stop loading indicator
      } catch (error) {
        setIsLoading(false)
        console.error('Failed to fetch notifications:', error);
      }
      finally {
        setIsLoading(false)
        // dispatch(setLoading(false))
      }
    }
  }, [setOpen, open, dispatch, userProject, role, userId]);

  return (
    <Tooltip title="Notification" placement="top">
      <div
        className='flex items-center'
      >
        <Popover
          content={notificationContent}
          trigger="click"
          placement="top"
          open={open}
          onOpenChange={handleChangePopOver}
          overlayStyle={{ width: '40vw', right: '0px'}}
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          <img
            src={hasUnreadNotifications ? NotificationWithRed : NotificationIcon}
            alt="notificationIcon"
            className="w-8 h-8 cursor-pointer mr-0" // Increase width and height to 10
            onClick={() => setOpen(!open)}
          />
        </Popover>
      </div>
    </Tooltip>
  );
};

export default memo(Notification);