import api from "../../api";

const baseUrl = "/api/user";


export const getUsers = () => {
  return api.get(baseUrl, { withCredentials: true });
  // return api.get(baseUrl);
};

export const addUser = (postData) => {
  return api.post(baseUrl, postData);

};

export const getPaginatedInternalUsers = (page, limit, searchTerm) => {
  return api.get(`${baseUrl}/users`, {
    params: {
      page,
      limit,
      search: searchTerm,
    },
    withCredentials: true, // Ensure it is inside the second object
  });
};


export const updateUser = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData);
};

export const deleteUser = (deleteId) => {
  return api.delete(`${baseUrl}/${deleteId}`);
};
