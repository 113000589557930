import { Modal, Row } from 'antd'
import React, { Suspense, useCallback, useMemo } from 'react'

const BarCharts = React.lazy(() => import('./BarChart'));

// Lazy-load the BarCharts component with a delay of 20 minutes
// const BarCharts = React.lazy(() =>
//  new Promise((resolve) => {
//   setTimeout(() => {
//    resolve(import('./BarChart')); // Dynamically import the component after 20 minutes
//   }, 1 * 60 * 1000); // 20 minutes in milliseconds (1,200,000 ms)
//  })
// );

const modalBodyStyle = { padding: 0, height: "80vh" };

const BarChartComponent = ({ modal2Open, setModal2Open, barData, fullScreen }) => {


  const modalContent = useMemo(() => (
    <Suspense fallback={
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="font-poppins text-center text-black text-opacity-100">
          <span className='font-poppins text-md text-black animate-fadeIn'>
            Hang on! chart's getting dressed<p className="text-4xl font-semibold inline animate-bounceDot"> . . .</p>
          </span>
        </div>
      </div>
    }>
      {barData?.length > 0 ? (
        <BarCharts data={barData} showLegend={modal2Open} modalOpen={true} 
        />
      ) : null}
    </Suspense>
  ), [barData, modal2Open]);

  const handleModalToggle = useCallback(() => {

    setModal2Open(false);
  }, [setModal2Open]);

  


  return (
    <>
      {modal2Open && (
        <Modal
        title={
          <span>
            Full screen bar chart
  
          </span>
        }
        
          style={{top:10, left:20  }}
          styles={{
            body: modalBodyStyle // Use this instead of bodyStyle
          }}
          open={modal2Open}
          onCancel={handleModalToggle}
          width="100%"
          footer={<span>
           
            <div className='flex items-center space-x-4 font-poppins  justify-center'>
              <div className="flex items-center">
                <div className="w-3 h-3 bg-blue-600 mr-2"></div> {/* Color indicator for Completed */}
                <span className='text-[#2563eb]'>Completed</span>
              </div>
              <div className="flex items-center">
                <div className="w-3 h-3 bg-blue-300 mr-2"></div> {/* Color indicator for Pending */}
                <span className='text-[#5cbbff]'>Pending</span>
              </div>
            </div>
          </span>}
          className="fixed w-full h-full"
          

        >
          {modalContent}
        </Modal>
      )}

      {barData?.length > 0 ? (
        <>
          <Suspense fallback={<>
            <div className="w-full h-full flex flex-col justify-center items-center">
              <div className="font-poppins text-center text-black text-opacity-100">
                <span className='font-poppins text-md text-black animate-fadeIn inline'>
                  Hang on! chart's getting dressed<p className="text-4xl font-semibold animate-pulse inline"> . . .</p>
                </span>
              </div>
            </div>


          </>}>
            <BarCharts data={barData} showLegend={false} modalOpen={false} />
          </Suspense>
          <Row>
            <img
              src={fullScreen}
              alt="fullScreen"
              className="absolute top-2 right-2 z-10 cursor-pointer"
              onClick={() => setModal2Open(true)}
            />
          </Row>
        </>
      ) : (
        null
      )}

    </>

  )
}

export default React.memo(BarChartComponent)