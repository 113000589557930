import api from "../../api";

const baseUrl = "/api/recentHistory";

export const getRecentHistories = (userId, projectId, page = 1, pageSize = 10, searchTerm) => {
  const url = projectId ? `${baseUrl}/${userId}/${projectId}` : `${baseUrl}/${userId}`;

  return api.get(url, {
    params: { page, pageSize, search: searchTerm },
    withCredentials: true, // Ensure it is inside the second object

  });
};

export const createOrUpdateRecentHistory = (userId, projectId, chartId) => {
  return api.post(`${baseUrl}`, {
    userId,
    chartId,
    projectId
  });
};