import React, { memo } from 'react';
import { Col, Row, Table } from 'antd';
import CountUp from 'react-countup';
import divIcon from "../../assets/svg_images/Icon.svg"
import dayjs from 'dayjs';

const ChartsSummary = memo(({ getUserProject, isWeek, datas }) => {
  const columns = [
    {
      title: <span className="text-xs font-bold font-poppins text-center">Verification Status</span>,
      dataIndex: 'age',
      width: "25%",
      responsive: ["xs", "sm"],
      render: text => <span className="text-xs font-medium font-poppins">{text}</span>,
    },
    {
      title: <span className="text-xs font-bold font-poppins text-center">Record Count</span>,
      dataIndex: 'address',
      width: "25%",
      responsive: ["xs", "sm"],
      render: text => <span className="text-xs font-medium font-poppins">{text}</span>,
    },
  ];

  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={12}
      className="h-[100%] border border-white shadow-lg rounded-xl p-2"
      style={{
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      }}
    >
      <Row className="p-2 mq450:flex mq450:flex-col mq450:w-[300px] mq450:justify-center">
        <Col span={8}>
          <div className="w-[100%] h-[215px] bg-[#458ff6] bg-opacity-10 rounded-2xl flex  items-center justify-center mq450:w-[200px] mq450:ms-2 mb-5">
            <div className="text-center p-4 ">
              <img src={divIcon} alt="Card Icon" className="mb-2" />
              <div className="text-[#458ff6] font-poppins">
                <div className="font-extrabold text-[1.928rem]">
                  <CountUp end={getUserProject?.length} duration={2} />
                </div>
                {isWeek && <small>( # of Charts this Week )</small>}
                <div className="font-semibold text-sm  mq450:text-sm">Grand Total</div>
                <div className="font-light text-sm whitespace-normal overflow-hidden text-ellipsis mq450:text-sm">
                  In Verification Repository {dayjs()?.format("YYYY")}
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col span={16}>
          <div className="max-w-[100%] custom-table ml-[5%]  mq450:w-[100%]">
            <div className="border rounded-lg">
              <div className="custom-ant-table-design mq450:w-[200px]">
                <Table
                  columns={columns}
                  dataSource={datas}
                  pagination={false}
                  scroll={{ y: 170, x: "max-content" }}
                  className="max-h-[100%]"
                  size="small"
                  rowHoverable={false}
                  locale={{
                    emptyText: (
                      <div style={{ textAlign: "center" }}>
                        <div>Data Not Found</div>
                      </div>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  );
});

export default ChartsSummary;
