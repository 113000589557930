import { createSlice } from '@reduxjs/toolkit';
import { createNotificationApi, deleteNotificatiApi, getNotificationsApi, updateNotificationApi } from './notificationService';

const initialState = {
  notificationList: [],
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // add your non-async reducers here
    logout: state => {
      state.notificationList = [];
      state.loading = 'idle';
      state.currentRequestId = undefined;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    // extraReducers handles asynchronous requests, which is our main focus.
    // GET
    builder
      .addCase(getNotificationsApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getNotificationsApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.notificationList.length = 0;
          // Ensure new notifications are added to the top
          state.notificationList.push(...action.payload.reverse());
          state.currentRequestId = undefined;
        }
      })
      .addCase(getNotificationsApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // POST
    builder
      .addCase(createNotificationApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(createNotificationApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';

          const notifications = Array.isArray(action.payload) ? action.payload : [action.payload]; // Ensure array
          notifications.forEach((notification) => {
            const existingIndex = state.notificationList.findIndex((notif) => notif._id === notification._id);
            if (existingIndex > -1) {
              state.notificationList[existingIndex] = { ...state.notificationList[existingIndex], ...notification };
            } else {
              state.notificationList.unshift(notification); // Add new notification at the top
            }
          });

          state.currentRequestId = undefined;
        }
      })
      // Working version 1
      // .addCase(createNotificationApi.fulfilled, (state, action) => {
      //   const { requestId } = action.meta;
      //   if (state.loading === 'pending' && state.currentRequestId === requestId) {
      //     state.loading = 'idle';

      //     const notification = action.payload; // Only one notification returned now
      //     const existingIndex = state.notificationList.findIndex((notif) => notif._id === notification._id);
      //     if (existingIndex > -1) {
      //       state.notificationList[existingIndex] = { ...state.notificationList[existingIndex], ...notification };
      //     } else {
      //       state.notificationList.unshift(notification); // Add new notification at the top
      //     }

      //     state.currentRequestId = undefined;
      //   }
      // })
      // Old one keep it
      // .addCase(createNotificationApi.fulfilled, (state, action) => {
      //   const { requestId } = action.meta;
      //   if (state.loading === 'pending' && state.currentRequestId === requestId) {
      //     state.loading = 'idle';

      //     if (Array.isArray(action.payload)) {
      //       action.payload.forEach((notification) => {
      //         const existingIndex = state.notificationList.findIndex((notif) => notif._id === notification._id);
      //         if (existingIndex > -1) {
      //           state.notificationList[existingIndex] = { ...state.notificationList[existingIndex], ...notification };
      //         } else {
      //           state.notificationList.unshift(notification);
      //         }
      //       });
      //     } else {
      //       const notification = action.payload;
      //       const existingIndex = state.notificationList.findIndex((notif) => notif._id === notification._id);
      //       if (existingIndex > -1) {
      //         state.notificationList[existingIndex] = { ...state.notificationList[existingIndex], ...notification };
      //       } else {
      //         state.notificationList.unshift(notification);
      //       }
      //     }

      //     state.currentRequestId = undefined;
      //   }
      // })
      .addCase(createNotificationApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // PUT
    builder
      .addCase(updateNotificationApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(updateNotificationApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          const updatedNotification = action.payload;

          // Find the index of the notification to update in the notificationList
          const index = state.notificationList.findIndex(
            (notif) => notif._id === updatedNotification._id
          );

          if (index > -1) {
            // Update the specific notification's read status
            state.notificationList[index] = {
              ...state.notificationList[index],
              ...updatedNotification
            };
          }

          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })

      // Old one keep this

      // .addCase(updateNotificationApi.fulfilled, (state, action) => {
      //   const { requestId } = action.meta;
      //   if (state.loading === 'pending' && state.currentRequestId === requestId) {
      //     const updatedItem = action.payload;

      //     // Ensure we handle only one updated item, not an array or duplicate structure.
      //     if (Array.isArray(updatedItem)) {
      //       updatedItem.forEach((item) => {
      //         const index = state.notificationList.findIndex((notif) => notif._id === item._id);
      //         if (index > -1) {
      //           state.notificationList[index] = { ...state.notificationList[index], ...item };
      //         }
      //       });
      //     } else {
      //       const index = state.notificationList.findIndex((notif) => notif._id === updatedItem._id);
      //       if (index > -1) {
      //         state.notificationList[index] = { ...state.notificationList[index], ...updatedItem };
      //       }
      //     }

      //     state.loading = 'idle';
      //     state.currentRequestId = undefined;
      //   }
      // })
      .addCase(updateNotificationApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // DELETE
    builder
      .addCase(deleteNotificatiApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteNotificatiApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          const index = state.notificationList.findIndex((item) => item.id === action.payload);
          if (index > -1) {
            state.notificationList.splice(index, 1);
          }
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteNotificatiApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

  }
})

// Action creators are generated for each case reducer function
export const { logout } = notificationSlice.actions;

export default notificationSlice.reducer;