/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import { Table } from 'antd';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { SmileOutlined } from '@ant-design/icons';

const roleMapping = {
  'Provider': 'Client Administrator',
  'VIEW': 'Visitor Viewer',
  'DOWNLOAD': 'Report Manager'
};

const getMappedRoleName = (name) => roleMapping[name] || name;

const RoleTable = ({
  roleList,
  searchQuery
}) => {

  const columns = [
    {
      title: 'Role Name',
      dataIndex: 'name',
      key: 'name',
      align: "center",
    },
    // Uncomment this section if you want to add edit and delete actions
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (_, record) => (
    //     <div className='w-full flex justify-end items-center'>
    //       <Tooltip title="edit">
    //         <button type='button' onClick={() => editPress(record.id)}>
    //           <FaEdit className='text-blue-500 text-lg' />
    //         </button>
    //       </Tooltip>
    //       <Tooltip title="delete">
    //         <button type='button' className='ml-2' onClick={() => deletePress(record.id)}>
    //           <IoMdTrash className='text-red-500 text-xl' />
    //         </button>
    //       </Tooltip>
    //     </div>
    //   )
    // }
  ];

  const data = roleList
    .filter((item) => {
      const originalName = item.name.toString().toLowerCase();
      const mappedName = getMappedRoleName(item.name).toString().toLowerCase();
      const query = searchQuery.toString().toLowerCase();
      return originalName.includes(query) || mappedName.includes(query);
    })
    .map(({ id, name }) => ({
      key: id,
      id: id,
      name: getMappedRoleName(name),
    }));

  return (
    <div className="border shadow-md rounded-md mb-3">
      <div className="custom-ant-table-design no-scrollbar">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          size='small'
          rowHoverable={false}
          locale={{
            emptyText: (
              <div style={{ textAlign: 'center' }}>
                <SmileOutlined style={{ fontSize: 20 }} />
                <p>Data Not Found</p>
              </div>
            ),
          }}
        // scroll={{ y: 400 }}
        />
      </div>
    </div>
  );
};

export default memo(RoleTable);
