import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useIdleTimer = (
  timeout = 1000 * 60 * 15 // default timeout of 15 minutes
) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isIdle, setIsIdle] = useState(false);
  const idleTimerRef = useRef(null); // Use useRef to store the timer reference
  const hasLoggedOut = useRef(false); // Use useRef instead of useState for `hasLoggedOut` to avoid unnecessary re-renders

  const { userId, role } = useSelector((state) => state.userDetails);


  const resetTimer = useCallback(() => {
    if (isIdle) setIsIdle(false);
    clearTimeout(idleTimerRef.current);

    idleTimerRef.current = setTimeout(async () => {
      // Ensure trackLogOut is called only once
      if (!hasLoggedOut.current) { // Access the current value of hasLoggedOut
        hasLoggedOut.current = true; // Update the ref without causing re-render
        try {

          const actionDetails = `Logged out by system due to inactivity`;

          // Importing the api calls function dynamically
          if (role !== "PROVIDER" && role !== "USER") {
            const { trackLogOut, trackUserAction } = await import('../api/userLog/userLog');
            await trackUserAction(userId, "System", actionDetails, role);
            await trackLogOut(userId, role);
          }

          const { createNotificationApi } = await import('../redux/notification/notificationService');

          const notifyObj = {
            title: `Session Timeout`,
            message: `Due to inactivity, you have been automatically logged out for security reasons.`,
            type: "System",
            userId: userId,
            role: role
          };
          await dispatch(createNotificationApi(notifyObj)).unwrap();


        } catch (error) {
          console.error('Error tracking log out:', error);
        } finally {
          const { clearUserDetails } = await import('../redux/userDetails/userDetailsSlice');

          if (role === "PROVIDER" || role==="USER" ) {
            navigate("/"); // Redirect PROVIDER role to root
          } else {
            navigate("/internal"); // Redirect other roles to /internal
          }

          dispatch(clearUserDetails());
          
        }
      }
    }, timeout);
  }, [dispatch, isIdle, navigate, role, timeout, userId]);


  useEffect(() => {
    // Set up event listeners to reset the timer on user action
    const events = ['mousemove', 'keydown', 'scroll', 'click', 'touchstart', 'touchmove', 'mousedown', 'wheel', 'resize', 'focus'];

    events?.forEach(event => window.addEventListener(event, resetTimer));

    // Start the idle timer when the hook is initialized
    idleTimerRef.current = setTimeout(() => {
      setIsIdle(true);
    }, timeout);

    // Cleanup event listeners and timers when the component unmounts
    return () => {
      clearTimeout(idleTimerRef.current);
      events.forEach(event => window.removeEventListener(event, resetTimer));
    };
  }, [resetTimer, timeout]);

  return isIdle;
};

export default useIdleTimer;
