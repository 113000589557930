import React, { useState } from "react";
import { Tabs } from 'antd';
import { useNavigate } from "react-router-dom";
import ProviderUser from "../ProviderUser/ProviderUser";
import Role from "../Role/Role";


const UserDirectoryTabs = ({ setTabName }) => {
  const [activeTabKey, setActiveTabKey] = useState("1");
  const navigate = useNavigate();

  const handleTabChange = (key) => {
    setActiveTabKey(key);

    let tabName;
    switch (key) {
      case "1":
        tabName = "Users";
        break;
      case "2":
        tabName = "Role";
        break;
      default:
        tabName = "Users";
    }

    setTabName(tabName);
    navigate(`/provider/user-directory/${key}`);
  };

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTabKey}
        onChange={handleTabChange}
        tabPosition="top"
      >
        <Tabs.TabPane tab="Users" key="1">
          <ProviderUser />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Role" key="2">
          <Role />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default UserDirectoryTabs;
