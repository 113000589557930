import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getDocuments,
  getTodayDocuments,
  addDocument,
  updateDocument,
  deleteDocument,
} from '../../api/document/document';

export const getDocumentsApi = createAsyncThunk(
  "document/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getDocuments();
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTodayDocumentsApi = createAsyncThunk(
  "document/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getTodayDocuments();
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createDocumentApi = createAsyncThunk(
  "document/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addDocument(data);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDocumentApi = createAsyncThunk(
  "document/update",
  async (data, { rejectWithValue }) => {
    const { updateId, items } = data;
    try {
      const response = await updateDocument(updateId, items);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDocumentApi = createAsyncThunk(
  "document/delete",
  async (deleteId, { rejectWithValue }) => {
    try {
      const { status } = await deleteDocument(deleteId);
      if (status === 202) return deleteId;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);
