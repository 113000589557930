export const Gender = [
  { value: "M", label: "M" },
  { value: "F", label: "F" },
  { value: "U/A", label: "U/A" },
  { value: "N/A", label: "N/A" }
];

export const Relationship = [
  { value: 'SELF', label: 'SELF' },
  { value: 'DEPENDENT', label: 'DEPENDENT' },
  { value: 'SPOUSE', label: 'SPOUSE' },
  { value: 'ADOPTED', label: 'ADOPTED' },
  { value: 'EMPLOYEE', label: 'EMPLOYEE' },
  { value: 'OTHER', label: 'OTHER' },
  { value: 'U/A', label: 'U/A' },
  { value: 'CHILD', label: 'CHILD' },
  { value: 'N/A', label: 'N/A' }
];

export const MaritalStatus = [
  { value: 'UNKNOWN', label: 'UNKNOWN' },
  { value: 'MARRIED', label: 'MARRIED' },
  { value: 'N/A', label: 'N/A' },
  { value: 'U/A', label: 'U/A' }
];

export const PortalAvailability = [
  { value: 'AVAILABLE', label: 'AVAILABLE' },
  { value: 'UNAVAILABLE', label: 'UNAVAILABLE' },
];

export const InsuranceCardDelivered = [
  { value: 'YES', label: 'YES' },
  { value: 'NO', label: 'NO' },
  { value: 'U/A', label: 'U/A' },
  { value: 'N/A', label: 'N/A' }
];

export const Multiplan = [
  { value: 'YES', label: 'YES' },
  { value: 'NO', label: 'NO' },
  { value: 'N/A', label: 'N/A' },
  { value: 'U/A', label: 'U/A' }
];

export const States = [
  { value: "AL", label: "ALABAMA" },
  { value: "AK", label: "ALASKA" },
  { value: "AZ", label: "ARIZONA" },
  { value: "AR", label: "ARKANSAS" },
  { value: "CA", label: "CALIFORNIA" },
  { value: "CO", label: "COLORADO" },
  { value: "CT", label: "CONNECTICUT" },
  { value: "DE", label: "DELAWARE" },
  { value: "FL", label: "FLORIDA" },
  { value: "GA", label: "GEORGIA" },
  { value: "GU", label: "GUAM" },
  { value: "HI", label: "HAWAII" },
  { value: "ID", label: "IDAHO" },
  { value: "IL", label: "ILLINOIS" },
  { value: "IN", label: "INDIANA" },
  { value: "IA", label: "IOWA" },
  { value: "KS", label: "KANSAS" },
  { value: "KY", label: "KENTUCKY" },
  { value: "LA", label: "LOUISIANA" },
  { value: "ME", label: "MAINE" },
  { value: "MD", label: "MARYLAND" },
  { value: "MA", label: "MASSACHUSETTS" },
  { value: "MI", label: "MICHIGAN" },
  { value: "MN", label: "MINNESOTA" },
  { value: "MS", label: "MISSISSIPPI" },
  { value: "MO", label: "MISSOURI" },
  { value: "MT", label: "MONTANA" },
  { value: "NE", label: "NEBRASKA" },
  { value: "NV", label: "NEVADA" },
  { value: "NH", label: "NEW HAMPSHIRE" },
  { value: "NJ", label: "NEW JERSEY" },
  { value: "NM", label: "NEW MEXICO" },
  { value: "NY", label: "NEW YORK" },
  { value: "NC", label: "NORTH CAROLINA" },
  { value: "ND", label: "NORTH DAKOTA" },
  { value: "OH", label: "OHIO" },
  { value: "OK", label: "OKLAHOMA" },
  { value: "OR", label: "OREGON" },
  { value: "PA", label: "PENNSYLVANIA" },
  { value: "PR", label: "PUERTO RICO" },
  { value: "RI", label: "RHODE ISLAND" },
  { value: "SC", label: "SOUTH CAROLINA" },
  { value: "SD", label: "SOUTH DAKOTA" },
  { value: "TN", label: "TENNESSEE" },
  { value: "TT", label: "TRUST TERRITORIES" },
  { value: "TX", label: "TEXAS" },
  { value: "UT", label: "UTAH" },
  { value: "VT", label: "VERMONT" },
  { value: "VA", label: "VIRGINIA" },
  { value: "WA", label: "WASHINGTON" },
  { value: "WV", label: "WEST VIRGINIA" },
  { value: "WI", label: "WISCONSIN" },
  { value: "WY", label: "WYOMING" },
  { value: "U/A", label: "U/A" },
  { value: "WR", label: "WEST REGION" },
  { value: "ER", label: "EAST REGION" },
  { value: "WNY", label: "WESTERN NEW YORK" },
  { value: "NONE", label: "NONE" },
  { value: "N/A", label: "N/A" }
];

export const ShortStates = [
  { value: "AL", label: "AL" },
  { value: "AK", label: "AK" },
  { value: "AZ", label: "AZ" },
  { value: "AR", label: "AR" },
  { value: "CA", label: "CA" },
  { value: "CO", label: "CO" },
  { value: "CT", label: "CT" },
  { value: "DE", label: "DE" },
  { value: "FL", label: "FL" },
  { value: "GA", label: "GA" },
  { value: "GU", label: "GU" },
  { value: "HI", label: "HI" },
  { value: "ID", label: "ID" },
  { value: "IL", label: "IL" },
  { value: "IN", label: "IN" },
  { value: "IA", label: "IA" },
  { value: "KS", label: "KS" },
  { value: "KY", label: "KY" },
  { value: "LA", label: "LA" },
  { value: "ME", label: "ME" },
  { value: "MD", label: "MD" },
  { value: "MA", label: "MA" },
  { value: "MI", label: "MI" },
  { value: "MN", label: "MN" },
  { value: "MS", label: "MS" },
  { value: "MO", label: "MO" },
  { value: "MT", label: "MT" },
  { value: "MP", label: "MP" },
  { value: "NE", label: "NE" },
  { value: "NV", label: "NV" },
  { value: "NH", label: "NH" },
  { value: "NJ", label: "NJ" },
  { value: "NM", label: "NM" },
  { value: "NY", label: "NY" },
  { value: "NC", label: "NC" },
  { value: "ND", label: "ND" },
  { value: "OH", label: "OH" },
  { value: "OK", label: "OK" },
  { value: "OR", label: "OR" },
  { value: "PA", label: "PA" },
  { value: "PR", label: "PR" },
  { value: "RI", label: "RI" },
  { value: "SC", label: "SC" },
  { value: "SD", label: "SD" },
  { value: "TN", label: "TN" },
  { value: "TT", label: "TT" },
  { value: "TX", label: "TX" },
  { value: "UT", label: "UT" },
  { value: "VT", label: "VT" },
  { value: "VI", label: "VI" },
  { value: "VA", label: "VA" },
  { value: "WA", label: "WA" },
  { value: "WV", label: "WV" },
  { value: "WI", label: "WI" },
  { value: "WY", label: "WY" },
  { value: "U/A", label: "U/A" },
  { value: "WR", label: "WR" },
  { value: "ER", label: "ER" },
  { value: "NONE", label: "NONE" },
  { value: "N/A", label: "N/A" }
];


export const NetworkStatus = [
  { value: 'INN', label: 'INN' },
  { value: 'OON', label: 'OON' },
  { value: 'N/A', label: 'N/A' },
  { value: 'U/A', label: 'U/A' }
];

export const AuthStatus = [
  { value: 'YES', label: 'YES' },
  { value: 'NO', label: 'NO' },
  { value: 'N/A', label: 'N/A' },
  { value: 'VARIES', label: 'VARIES' },
  { value: 'U/A', label: 'U/A' }
];

export const VFCategory = [
  { value: 'Approved', label: 'Approved' },
  { value: 'Approved_Closed', label: 'Approved_Closed' },
  { value: 'Approved_LMN_VF Closed', label: 'Approved_LMN_VF Closed' },
  { value: 'Approved_POD_VF Closed', label: 'Approved_POD_VF Closed' },
  { value: 'In-Active', label: 'In-Active' },
  { value: 'Missing Demo/Insurance Info', label: 'Missing Demo/Insurance Info' },
  { value: 'Moved to PA', label: 'Moved to PA' },
  { value: 'MR_VF Closed', label: 'MR_VF Closed' },
  { value: 'PA Approved', label: 'PA Approved' },
  { value: 'PA Approved_Closed', label: 'PA Approved_Closed' },
  { value: 'PA Approved_POD _VF Closed', label: 'PA Approved_POD _VF Closed' },
  { value: 'PA Denied_Closed', label: 'PA Denied_Closed' },
  { value: 'PA Held', label: 'PA Held' },
  { value: 'PA LMN_VF Closed', label: 'PA LMN_VF Closed' },
  { value: 'PA Pending', label: 'PA Pending' },
  { value: 'Subsequent VF In-Process', label: 'Subsequent VF In-Process' },
  { value: 'VF Closed', label: 'VF Closed' },
  { value: 'VF Denied_Closed', label: 'VF Denied_Closed' },
  { value: 'VF Held', label: 'VF Held' },
  { value: 'VF Held_Closed', label: 'VF Held_Closed' },
  { value: 'VF In-Process', label: 'VF In-Process' },
  { value: 'PA Review Required', label: 'PA Review Required' },
  { value: 'PA Not Required_Approved', label: 'PA Not Required_Approved' },
  { value: 'Aetna_Approved', label: 'Aetna_Approved' },
  { value: 'Aetna_Approved_Closed', label: 'Aetna_Approved_Closed' },
  { value: 'Rental_Approved', label: 'Rental_Approved' },
  { value: 'Purchase_Approved', label: 'Purchase_Approved' },
  { value: 'Rental_Approved_Closed', label: 'Rental_Approved_Closed' },
  { value: 'Purchase_Approved_Closed', label: 'Purchase_Approved_Closed' },
  { value: 'PA RR_Approved', label: 'PA RR_Approved' },
  { value: 'S&S Constraints - Cross-Facility Authorization Issue', label: 'S&S Constraints - Cross-Facility Authorization Issue' },

];

export const SsReceivedEquipment = [
  { value: 'YES', label: 'YES' },
  { value: 'NO', label: 'NO' },
  { value: 'N/A', label: 'N/A' },
  { value: 'U/A', label: 'U/A' }
];

export const ConsultationStatus = [
  { value: 'Completed', label: 'Completed' },
  { value: 'Pending', label: 'Pending' },
  { value: 'N/A', label: 'N/A' },
  { value: 'U/A', label: 'U/A' }
];

export const CallingAssignedReason = [
  { value: 'VF In-Process: Portal Available - Unable To Engage', label: 'VF In-Process: Portal Available - Unable To Engage' },
  { value: 'VF In-Process: Unable To Engage', label: 'VF In-Process: Unable To Engage' },
  { value: 'PA Held: Peer Review Required', label: 'PA Held: Peer Review Required' },
  { value: 'Pending Verification: Missing Required Information', label: 'Pending Verification: Missing Required Information' },
  { value: 'VF In-Process: Portal Available - Unable to obtain PA requirements', label: 'VF In Process: Portal Available - Unable to obtain PA requirements' },
  { value: 'VF In-Process: Portal Unavailable', label: 'VF In Process: Portal Unavailable' },
  { value: 'VF In-Process: Prior Authorization Status Needed', label: 'VF In Process: Prior Authorization Status Needed' },
  { value: 'VF In-Process: Check COB', label: 'VF In Process: Check COB' },
  { value: 'VF In-Process: S&S', label: 'VF In Process: S&S' },
  { value: 'VF In-Process: Check PCB', label: 'VF In Process: Check PCB' },
  { value: 'VF In-Process: Insurance Card Available & Portal Available, Not Found In Portal', label: 'VF In Process: Insurance Card Available & Portal Available, Not Found In Portal' },
  { value: 'VF In-Process: Confirmation Of Primary & Secondary Insurance Required', label: 'VF In Process: Confirmation Of Primary & Secondary Insurance Required' },
  { value: 'VF In-Process: Confirmation Of Network Status', label: 'VF In Process: Confirmation Of Network Status' },
  { value: 'VF In-Process: NO DME Benefits In Portal', label: 'VF In Process: NO DME Benefits In Portal' },
  { value: 'VF In-Process: INN DME Benefits Unavailable In Portal & OON DME Benefits Available, Check INN Benefits', label: 'VF In Process: INN DME Benefits Unavailable In Portal & OON DME Benefits Available, Check INN Benefits' },
  { value: 'Unassigned Call', label: 'Unassigned Call' },
  { value: 'N/A', label: 'N/A' },
  { value: 'U/A', label: 'U/A' }
];


export const CalendarMonth = [
  { value: 'JANUARY', label: 'JANUARY' },
  { value: 'FEBRUARY', label: 'FEBRUARY' },
  { value: 'MARCH', label: 'MARCH' },
  { value: 'APRIL', label: 'APRIL' },
  { value: 'MAY', label: 'MAY' },
  { value: 'JUNE', label: 'JUNE' },
  { value: 'JULY', label: 'JULY' },
  { value: 'AUGUST', label: 'AUGUST' },
  { value: 'SEPTEMBER', label: 'SEPTEMBER' },
  { value: 'OCTOBER', label: 'OCTOBER' },
  { value: 'NOVEMBER', label: 'NOVEMBER' },
  { value: 'DECEMBER', label: 'DECEMBER' },
  { value: 'N/A', label: 'N/A' },
  { value: 'U/A', label: 'U/A' }

];

export const BenefitsDME = [
  {
    value: 'YES',
    label: 'YES',
  },
  {
    value: 'NO',
    label: 'NO',
  },
  {
    value: 'N/A',
    label: 'N/A'
  },
  {
    value: 'U/A',
    label: 'U/A'
  }
];

export const CallMode = [
  {
    value: 'N/A',
    label: 'N/A',
  },
  {
    value: 'IVR',
    label: 'IVR',
  },
  {
    value: 'IR',
    label: 'IR',
  },
  {
    value: 'N/A',
    label: 'N/A'
  },
  {
    value: 'U/A',
    label: 'U/A'
  }
];

export const CallingDispositionCode = [
  {
    value: 'WWS_01_VF Completed',
    label: 'WWS_01_VF Completed',
  },
  {
    value: 'WWS_02_LVM',
    label: 'WWS_02_LVM',
  },
  {
    value: 'WWS_03_Call Completed: VF Incomplete',
    label: 'WWS_03_Call Completed: VF Incomplete',
  },
  {
    value: 'WWS_04_Incorrect Phone #',
    label: 'WWS_04_Incorrect Phone #',
  },
  {
    value: 'WWS_05_Inbound',
    label: 'WWS_05_Inbound',
  },
  {
    value: 'WWS_06_Spam',
    label: 'WWS_06_Spam',
  },
  {
    value: 'WWS_07_Inbound PA',
    label: 'WWS_07_Inbound PA',
  },
  {
    value: 'WWS_08_Follow Up Needed - Additional follow up required for verification',
    label: 'WWS_08_Follow Up Needed - Additional follow up required for verification',
  },
  {
    value: 'WWS_09_Technical Issues - Technical issues prevented completion',
    label: 'WWS_09_Technical Issues - Technical issues prevented completion',
  },
  {
    value: 'WWS_10_No Answer - No answer on outbound call',
    label: 'WWS_10_No Answer - No answer on outbound call',
  },
  {
    value: 'N/A',
    label: 'N/A'
  },
  {
    value: 'U/A',
    label: 'U/A'
  }
];

export const crDispositionCode = [
  {
    value: 'IR1 - Receptive and Cooperative:',
    label: 'IR1 - Receptive and Cooperative:',
  },
  {
    value: 'IR2 - Uncooperative or Hostile:',
    label: 'IR2 - Uncooperative or Hostile:',
  },
  {
    value: 'IR3 - Requested Additional Information:',
    label: 'IR3 - Requested Additional Information:',
  },
  {
    value: 'IR4 - Resolved Issue:',
    label: 'IR4 - Resolved Issue:',
  },
  {
    value: 'IR5 - Transferred Call:',
    label: 'IR5 - Transferred Call:',
  },
  {
    value: 'IR6 - Rep Hung Up:',
    label: 'IR6 - Rep Hung Up:',
  },
  {
    value: 'IR7 - Incomplete Information:',
    label: 'IR7 - Incomplete Information:',
  },
  {
    value: 'X1 - System Error Encountered @ Reps End',
    label: 'X1 - System Error Encountered @ Reps End',
  },
  {
    value: 'N/A',
    label: 'N/A'
  },
  {
    value: 'U/A',
    label: 'U/A'
  }
];
export const Reports = [

  {
    label: 'Monthly Charts Insights Summary',
    value: 'Monthly Verification Summary Reports',
  },
  {
    label: 'Verification Weekly Summary Report',
    value: 'Verification Weekly Summary Report',
  },
  {
    label: 'Day to Day Report',
    value: 'Day to Day Report',
  },
  {
    label: 'VF Report',
    value: 'VF Report',
  },

];
export const automateEquipmentData = [
  {
    equipment: 'Back Brace',
    codes: ['L0650', 'L0651', 'L0648']
  },
  {
    equipment: 'Tens Unit',
    codes: ['E0730', 'E0731', 'A4630', 'L7362', 'A4556', 'A4557', 'A4558', 'A4595']
  },
  {
    equipment: 'SCD',
    codes: ['E0650', 'E0651', 'E0652', 'E0655', 'E0656', 'E0657', 'E0660', 'E0665', 'E0666', 'E0667', 'E0668', 'E0669', 'E0670', 'E0671', 'E0672', 'E0673', 'E0675', 'E0676']
  },
  {
    equipment: 'Knee Brace',
    codes: ['L1832', 'L1833', 'L2397', 'L2795', 'L2810']
  },
  {
    equipment: 'NMES',
    codes: ['E0745', 'E0731', 'A4630', 'L7362', 'A4556', 'A4557', 'A4558']
  },
  {
    equipment: 'Wrist Brace',
    codes: ['L3809']
  },
  {
    equipment: 'Shoulder Brace',
    codes: ['L3670']
  },
  {
    equipment: 'Anklet Brace',
    codes: ['L1902']
  },
  {
    equipment: 'Shoulder elbow wrist hand orthosis',
    codes: ['L3960']
  },
  {
    equipment: 'Hip Orthosis',
    codes: ['L1686']
  },
  {
    equipment: 'Walking Boot',
    codes: ['L4361']
  },
  {
    equipment: 'Pediatric Wheelchair',
    codes: ['E1236', 'E0316', 'E0961', 'E0159', 'K0069', 'E0945', 'E1298', 'K0071', 'E0950', 'E2340', 'E1297', 'E2204', 'E2228']
  },
  {
    equipment: 'Maternity Support Belt',
    codes: ['L0621', 'A4466']
  },
  {
    equipment: 'Compression Stocks',
    codes: ['A6501', 'A6502', 'A6503', 'A6504', 'A6505', 'A6506', 'A6507', 'A6508', 'A6509', 'A6510', 'A6511', 'A6512', 'A6513', 'A6530', 'A6531', 'A6532', 'A6533', 'A6534', 'A6535', 'A6536', 'A6537', 'A6538', 'A6539', 'A6540', 'A6541', 'A6543', 'A6544', 'A6549']
  },
  {
    equipment: 'Pelvic Sling',
    codes: ['L2580']
  },
  {
    equipment: 'Lift Chairs',
    codes: ['E0170', 'E0171', 'E0172', 'E0627', 'E0628', 'E0629', 'E1036', 'E1035']
  },
  {
    equipment: 'Breast Pump',
    codes: ['E0602', 'E0603', 'E0604', 'A4281', 'A4282', 'A4283', 'A4284', 'A4285', 'A4286', 'K1005']
  },
  {
    equipment: 'Apnea monitors',
    codes: ['E0618', 'E0619', 'A4556', 'A4557']
  },
  {
    equipment: 'Walker',
    codes: ['E0144']
  },
  {
    equipment: 'AAC Device',
    codes: ['E2510']
  },
  {
    equipment: 'Milk Storage Bags',
    codes: ['A4287']
  },
  {
    equipment: 'Ultraviolet light',
    codes: ['E0691']
  },
  {
    equipment: 'Wheelchair',
    codes: ['K0001']
  },
  {
    equipment: 'Diabetic Shoes',
    codes: ['A5500']
  },
  {
    equipment: 'Wheelchair accessory',
    codes: ['E2601', 'E2613', 'E0990', 'K0195', 'E0973', 'E0978', 'E0956']
  },
  {
    equipment: 'CPAP',
    codes: ['E0601']
  },
  {
    equipment: 'CPAP ACCESSORIES',
    codes: ['A4604', 'A7038', 'A7046']
  },
  {
    equipment: 'Oximeter accessory',
    codes: ['A4455']
  },
  {
    equipment: 'Oximeter',
    codes: ['E0445']
  },
  {
    equipment: 'INCONTINENCE PADS',
    codes: ['A4520']
  },
  {
    equipment: 'CGM',
    codes: ['E2103']
  }
];

export const prescriptionClassifications = [

  {
    label: 'LMN - Clear To be Processed',
    value: 'LMN - Clear To be Processed',
  },
  {
    label: 'LMN - Insufficient_NPI',
    value: 'LMN - Insufficient_NPI',
  },
  {
    label: 'LMN - Insufficient_HCPCS',
    value: 'LMN - Insufficient_HCPCS',
  },
  {
    label: 'LMN - Insufficient_Dx',
    value: 'LMN - Insufficient_Dx',
  },
  {
    label: 'LMN - Insufficient_Order Data',
    value: 'LMN - Insufficient_Order Data',
  },
  {
    label: 'LMN - Insufficient_Image Unclear',
    value: 'LMN - Insufficient_Image Unclear',
  },
  {
    label: 'LMN - Unavailable',
    value: 'LMN - Unavailable',
  },
  {
    label: 'LMN - Query update',
    value: 'LMN - Query update',
  }
]



