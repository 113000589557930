//Triggering Build
import axios from "axios";

//Production URL
export const baseURL = "https://bmapi.mywwsbm.com/";
//Testing URL
// export const baseURL = "https://testingbmapi.mywwsbm.com/";
//Local URL
// export const baseURL = 'http://localhost:5000/';

export const idealLogOutTime = 1000 * 60 * 30; // when user inactivity automatically log out timer 30 minutes

const instance = axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

export default instance;