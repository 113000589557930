import React from 'react';
import { Layout, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { globalFooterHeight } from '../constants/constants';
import { FaQuestion } from 'react-icons/fa6';
import { useSelector } from 'react-redux';

const { Footer } = Layout;

const AntFooter = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.userDetails);
  const showHelpButton = role === "PROVIDER" || role === "USER";
  return (
    <Footer
      className="relative font-poppins font-bold text-xs text-center bg-white flex items-center justify-center"
      style={{ height: globalFooterHeight }}
    >
      <div>
        © {new Date().getFullYear()} : MYWWS Business Manager | Version V1.0.0 |
        <span
          className="cursor-pointer text-blue-bm font-poppins"
          onClick={() => window.open("/privacyPolicy", "_blank")}
        >
          &nbsp;Privacy policy
        </span>
      </div>
      {showHelpButton  && (
        <Button
          type="primary"
          className="absolute right-6 top-1/2 transform -translate-y-1/2 transition-all hover:scale-105 hover:bg-blue-600 duration-300 ease-in-out text-xs px-2 py-1"
          onClick={() => navigate('/provider/faq')}
        >
          Need Help <FaQuestion />
        </Button>
      )}
    </Footer>
  );
};

export default AntFooter;
