import React, { memo } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SearchBar = ({
  searchQuery,
  setSearchQuery,
  setSearchTerm
}) => {
  const handleChange = (event) => {
    const targetValue = event.target.value;
    setSearchTerm(targetValue);
    setSearchQuery(targetValue);
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const trimmedText = pastedText.trim();
    setSearchQuery(trimmedText);
  };

  return (
    <div className="w-full">
      <Input
        type="text"
        className="w-full border-2 rounded-xl focus:outline-none"
        value={searchQuery}
        onChange={handleChange}
        onPaste={handlePaste}
        placeholder="Search..."
        allowClear
        prefix={<SearchOutlined />}
      />
    </div>
  );
};

export default memo(SearchBar);