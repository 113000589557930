import { createSlice, createListenerMiddleware } from '@reduxjs/toolkit';
import {
  getRolesApi,
  createRoleApi,
  updateRoleApi,
  deleteRoleApi
} from './roleService';
import { setLoading } from '../loading/loadingSlice';

const initialState = {
  roleList: [],
  loading: 'idle',
  currentRequestId: undefined,
  listenerActive: false, // Flag to control the listener
  error: null,
}

export const roleListenerMiddleWare = createListenerMiddleware();


let listenerUnsubscribe; // Variable to hold the unsubscribe function for the listener

roleListenerMiddleWare.startListening({
  predicate: (action) =>
    [
      createRoleApi.fulfilled.type,
      updateRoleApi.fulfilled.type,
      deleteRoleApi.fulfilled.type,
    ].includes(action.type),
  effect: async (_, { dispatch }) => {

    try {
      dispatch(setLoading(true));
      await dispatch(getRolesApi()).unwrap();

    } catch (error) {

    } finally {
      dispatch(setLoading(false));
    }
  },
});



// Function to start the listener
const startRoleListener = () => {
  listenerUnsubscribe = roleListenerMiddleWare.startListening({
    predicate: (_, currentState) => {
      const { roleList, listenerActive } = currentState.role;
      const isListEmpty = roleList?.length === 0;

      if (!isListEmpty) {
        return false; // Stop evaluating the predicate
      }

      if (listenerActive) {
        return false; // Prevent redundant activation
      }

      return isListEmpty && !listenerActive; // Trigger only if roleList is empty
    },
    effect: async (_, { dispatch, getState, unsubscribe }) => {
      dispatch(setListenerActive(true)); // Mark listener as active
      try {
        dispatch(setLoading(true));

        // Fetch the role list
        await dispatch(getRolesApi()).unwrap();

        const { roleList } = getState().role;
        if (roleList.length > 0) {
          dispatch(setListenerActive(false)); // Deactivate listener
          unsubscribe(); // Stop the listener
        }
      } catch (error) {
        dispatch(setListenerActive(false)); // Reset listener flag in case of failure
      } finally {
        dispatch(setLoading(false));
      }
    },
  });
};

// Start the listener initially
startRoleListener();

// Restart the listener when needed
export const restartRoleListener = () => {
  if (listenerUnsubscribe) {
    listenerUnsubscribe(); // Unsubscribe the existing listener
  }
  startRoleListener(); // Start a new listener
};

export const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    // add your non-async reducers here
    setListenerActive(state, action) {
      state.listenerActive = action.payload;
    },
    // add your non-async reducers here
    logout: state => {
      state.roleList = [];
      state.loading = 'idle';
      state.currentRequestId = undefined;
      state.error = null;
      state.listenerActive = false; // Ensure listener is deactivated on logout
    }
  },
  extraReducers: (builder) => {
    // extraReducers handles asynchronous requests, which is our main focus.
    // GET
    builder
      .addCase(getRolesApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getRolesApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.roleList.length = 0;
          state.roleList.push(...action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(getRolesApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // POST
    builder
      .addCase(createRoleApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(createRoleApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.roleList.unshift(action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(createRoleApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // PUT
    builder
      .addCase(updateRoleApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(updateRoleApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          const updateItem = action.payload;
          const index = state.roleList.findIndex((item) => item.id === updateItem.id);
          if (index > -1) {
            state.roleList[index] = updateItem;
          }
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(updateRoleApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // DELETE
    builder
      .addCase(deleteRoleApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteRoleApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          const index = state.roleList.findIndex((item) => item.id === action.payload);
          if (index > -1) {
            state.roleList.splice(index, 1);
          }
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteRoleApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })
  }
})

// Action creators are generated for each case reducer function
export const { logout, setListenerActive } = roleSlice.actions;
export default roleSlice.reducer;