import React from 'react';
import { List, Typography, Tabs } from 'antd';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;
const { Text } = Typography;

const renderDiff = (changes, fieldTitles) => {
  const diffs = [];

  // Iterate over the fieldTitles keys to ensure the order of rendering
  Object.keys(fieldTitles).forEach(key => {
    if (changes.hasOwnProperty(key)) {
      const { previousValue, newValue } = changes[key];

      // Handle specific complex fields separately
      const formatComplexValue = (value, formatter) => {
        return Array.isArray(value) ? value.map(formatter) : 'N/A';
      };

      if (key === 'phoneMailEquipmentModels') {
        const formatPhonesAndEmails = (item, index) => (
          <div key={index}>
            <div><strong>Home/Phone #{index + 1}:</strong> {item?.phoneNumber}</div>
            <div><strong>Email ID #{index + 1}:</strong> {item?.mailId}</div>
          </div>
        );

        diffs.push({
          key,
          title: fieldTitles[key],
          previousValue: formatComplexValue(previousValue, formatPhonesAndEmails),
          newValue: formatComplexValue(newValue, formatPhonesAndEmails),
        });
      } else if (key === 'vfRequestedEquipmentModelDetails') {
        const formatVfRequestedEquipment = (item, index) => (
          <div key={index}>
            <div><strong>Equipment Model #{index + 1}:</strong> {item?.vfRequestedEquipmentModel}</div>
            <div><strong>HCPCS Code #{index + 1}:</strong> {item?.hcpcsCodes}</div>
            <div><strong>Equipment Name #{index + 1}:</strong> {item?.equipmentName}</div>
          </div>
        );

        diffs.push({
          key,
          title: fieldTitles[key],
          previousValue: formatComplexValue(previousValue, formatVfRequestedEquipment),
          newValue: formatComplexValue(newValue, formatVfRequestedEquipment),
        });
      } else if (key === 'prescriptionModelDetails') {
        const formatPrescription = (item, index) => (
          <div key={index}>
            <div><strong>HCPCS From Prescription #{index + 1}:</strong> {item?.hcpcsFormPrescription}</div>
            <div><strong>Equipment Model From Prescription #{index + 1}:</strong> {item?.equipmentModelFormPrescription}</div>
          </div>
        );

        diffs.push({
          key,
          title: fieldTitles[key],
          previousValue: formatComplexValue(previousValue, formatPrescription),
          newValue: formatComplexValue(newValue, formatPrescription),
        });
      } else if (key === 'orderingPhysicianModelDetails') {
        const formatOrderingPhysician = (item, index) => (
          <div key={index}>
            <div><strong>Ordering Physician NPI #{index + 1}:</strong> {item?.orderingPhysicianNPI}</div>
            <div><strong>Ordering Physician #{index + 1}:</strong> {item?.orderingPhysician}</div>
            <div><strong>Ordering Physician Address #{index + 1}:</strong> {item?.orderingPhysicianAddress}</div>
            <div><strong>Ordering Physician Phone Number #{index + 1}:</strong> {item?.orderingPhysicianPhoneNumber}</div>
            <div><strong>Ordering Physician Fax Number #{index + 1}:</strong> {item?.orderingPhysicianFaxNumber}</div>
            <div><strong>Ordering Physician Speciality #{index + 1}:</strong> {item?.orderingPhysicianSpeciality}</div>
          </div>
        );

        diffs.push({
          key,
          title: fieldTitles[key],
          previousValue: formatComplexValue(previousValue, formatOrderingPhysician),
          newValue: formatComplexValue(newValue, formatOrderingPhysician),
        });
      } else if (key === 'DxCodesModelDetails') {
        const formatDxCodes = (item, index) => (
          <div key={index}>
            <div><strong>DX Codes #{index + 1}:</strong> {item?.dxCodes}</div>
          </div>
        );

        diffs.push({
          key,
          title: fieldTitles[key],
          previousValue: formatComplexValue(previousValue, formatDxCodes),
          newValue: formatComplexValue(newValue, formatDxCodes),
        });
      } else {
        diffs.push({
          key,
          title: fieldTitles[key],
          previousValue: previousValue !== undefined ? previousValue : 'N/A',
          newValue: newValue !== undefined ? newValue : 'N/A',
        });
      }
    }
  });

  return diffs;
};

const VersionHistory = ({ versions }) => {
  const { role } = useSelector((state) => state.userDetails);
  const fieldTitles = {
    internalFtpDate: "Internal FTP Date",
    receivedOn: "Patient Received On",
    initialSubsequentDate: "Subsequent Scan Date",
    patientName: "Patient Name",
    dob: "DOB",
    age: "Age",
    patientPrimaryInsurance: "Primary Insurance #",
    ssnNumber: "SSN #",
    gender: "Gender",
    relationship: "Relationship",
    subscriberName: "Subscriber Name",
    subscriberDob: "Subscriber DOB",
    maritalStatus: "Marital Status",
    receivedAddress: "Address Received in PRF/LMN",
    address: "Address",
    city: "City",
    state: "State",
    zipCode: "Zip",
    phoneMailEquipmentModels: "Home/Phone # & Mail ID",
    portalAvailability: "Portal Availability",
    insuranceCardReceivedOn: "Insurance Card Received On",
    insuranceCard: "Insurance Card",
    multiplan: "Multiplan",
    icPayerID: "IC Payer ID",
    primaryInsurance: "Primary Insurance",
    primaryInsuranceState: "Primary Insurance State",
    primaryInsurancePlan: "Primary Insurance Plan",
    primaryInsuranceGroupNumber: "Primary Insurance Group #",
    secondaryInsurance: "Secondary Insurance",
    secondaryInsuranceState: "Secondary Insurance State",
    secondaryInsurancePlan: "Secondary Insurance Plan",
    secondaryInsuranceNumber: "Secondary Insurance #",
    secondaryInsuranceGroupNumber: "Secondary Insurance Group #",
    vfRequestedEquipmentModelDetails: "VF Requested Equipment Model",
    babyDueDate: "Baby Due Date",
    weeksDue: "Weeks Due",
    prescriptionModelDetails: "HCPCS From Prescription",
    orderDate: "Order Date",
    prescriptionClassification: "Prescription Classification",
    orderingPhysicianModelDetails: "Ordering Physician NPI",
    prescriptionReceivedOn: "LMN Received On",
    DxCodesModelDetails: "Dx Codes",
    pcpNpiNumber: "PCP NPI Number",
    PCP: "PCP",
    pcpAddress: "PCP Address",
    pcpPhoneNumber: "PCP Phone Number",
    pcpFaxNumber: "PCP Fax Number",
    pcpSpeciality: "PCP Speciality",
    vfTAT: "VF TAT",
    lmnOrderDate: "LMN Order Date",
    prescribingPhysician: "Prescribing Physician",
    npiNumber: "NPI Number",
    npiVerificationMethod: "NPI Verification Method",
    physicianSignature: "Physician Signature",
    physicianPhone: "Physician Phone",
    physicianFax: "Physician Fax",
    hcpcsCode: "HCPCS Code",
    itemEquipmentDescription: "Item/Equipment Description",
    quantity: "Quantity",
    diagnosisCode: "Diagnosis Code(s)",
    lmnStartDate: "Start Date",
    lmnEndDate: "End Date",
    orderType: "Order Type",
    faceToFaceNotes: "Face-to-Face Notes",
    progressNotesMedicalRecords: "Progress Notes/Medical Records",
    patientMedicalHistory: "Patient Medical History",
    sizing: "Sizing",
    customization: "Customization",
    accessoriesSupplies: "Accessories/Supplies",
    hipaaCompliant: "HIPAA Compliant",
    medicareMedicaidCompliant: "Medicare/Medicaid Compliant",
    stateRegulationsCompliant: "State Regulations Compliant",
    additionalClinicalInfoIncluded: "Additional Clinical Info Included",
    resolvable: "Resolvable",
    additionalNotes: "Additional Notes",
    validationStatus: "Validation Status",
    reasonForDenialPending: "Reason for Denial/Pending",
    validationDate: "Validation Date",
    calendarMonth: "Calendar Month",
    coverageStartDate: "Coverage Start Date",
    coverageEndDate: "Coverage End Date",
    networkStatus: "Network Status",
    innBenefits: "INN Benefits For DME",
    innDedCalendar: "INN Ded Calendar/Actual Amount",
    innDedMetAmount: "INN Ded Met Amount",
    innDedBalanceAmount: "INN Ded Balance Amount",
    innCoins: "INN Coins",
    innOopCalendar: "INN OOP Calendar/Actual Amount",
    innOopMetAmount: "INN OOP Met Amount",
    onnBenefits: "OON Benefits For DME",
    oonDedCalendar: "OON Ded Calendar/Actual Amount",
    oonDedMetAmount: "OON Ded Met Amount",
    ooDedBalanceAmount: "OON Ded Balance Amount",
    oonCoins: "OON Coins",
    oonOopCalendar: "OON OOP Calendar/Actual Amount",
    oonOopMet: "OON OOP Met Amount",
    authRequired: "Auth Required",
    vfCategory: "Chart Status",
    ssReceived: "S&S Received Equipment In the Last 6 Months?",
    consultationStatus: "Prescription Availability",
    vfComments: "VF Comments",
    subsequentScanDate: "Subsequent VF Date",
    subsequentQuarter: "Subsequent Quarter To Verify",
    transaction: "Transaction ID/SSA",
    callingAssigned: "Calling Assigned Reason",
    callingAdded: "Calling Added On",
    deliveryStatus: "Delivery Status",
    deliveredOn: "Delivered On",
    internalNotes: "Internal Notes Space Column",
    callingCompletedOn: "Verify Calling Completed On",
    insurancePhoneNumber: "Insurance Phone Number",
    pcb: "PCB",
    callingComments: "Notes",
    callRefNumber: "Reference Number",
    callingPayerId: "Payer ID",
    callMode: "Calling Mode",
    operator: "Operator",
    startTime: "Start Time",
    endTime: "End Time",
    ach: "ACH",
    callDispositionCode: "Call Disposition Code",
    crDispositionCode: "CR Disposition Code",
  };

  return (
    <Tabs>
      {versions.map(version => (
        <TabPane tab={`Version ${version?.version}`} key={version?._id}>

          <div className="h-80 overflow-y-auto">

            <List
              header={
                role !== "PROVIDER" && role !== "USER" ?
                  (
                    <div style={{ padding: '10px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <div style={{ flex: '1 1 25%', minWidth: '150px' }}>
                          <Text strong>Changed by: </Text>
                          <Text>{version?.changedBy?.name}</Text>
                        </div>
                        <div style={{ flex: '1 1 25%', minWidth: '150px' }}>
                          <Text strong>Change Date: </Text>
                          <Text>{new Date(version?.changedAt).toLocaleString()}</Text>
                        </div>
                        <div style={{ flex: '1 1 25%', minWidth: '150px' }}>
                          <Text strong>IP Address: </Text>
                          <Text>{version?.ipDetails?.ipAddress}</Text>
                        </div>
                        <div style={{ flex: '1 1 25%', minWidth: '150px' }}>
                          <Text strong>Location: </Text>
                          <Text>{`${version?.ipDetails?.city}, ${version?.ipDetails?.state}, ${version?.ipDetails?.country}`}</Text>
                        </div>
                      </div>
                    </div>
                  ) : null}
              bordered
              dataSource={renderDiff(version?.changes, fieldTitles)}
              renderItem={item => (
                <List.Item>
                  <Text strong>{item?.title}: </Text>
                  <div className="ml-4">
                    <Text type="danger">
                      {item?.previousValue === undefined ? 'N/A' : item?.previousValue}
                    </Text>
                    {' '}→{' '}
                    <Text type="success">
                      {item?.newValue === undefined ? 'N/A' : item?.newValue}
                    </Text>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </TabPane>
      ))}
    </Tabs>
  );
};

export default VersionHistory;
