import React from 'react'
import ModalBox from '../../components/ModalBox';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setLoading } from '../../redux/loading/loadingSlice';
import { deleteProviderApi } from '../../redux/provider/providerService';
import { axiosErrorHandling } from '../../utils/utility';
import { createNotificationApi } from '../../redux/notification/notificationService';

const DeleteProviderUser = ({
  deleteStatus,
  setDeleteStatus,
  deleteData,
  setDeleteData,
  refreshUsers
}) => {

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.userDetails.userId);
  const role = useSelector((state) => state.userDetails.role);

  const getContent = () => {
    let content = '';

    if (deleteData) {
      content = <p>Are you sure to delete this user <strong>{deleteData?.firstName} {deleteData?.lastName}</strong> ?</p>
    } else {
      content = <p>Are you sure to delete this user?</p>
    }
    return (
      <div className="w-full text-center">
        {content}
      </div>
    )
  }

  const onSubmitDeleteUser = async () => {
    try {
      if (!deleteData.id && !deleteData?._id) {
        return toast.warning('Invalid id!');
      }

      setDeleteStatus(false);
      dispatch(setLoading(true));
      await dispatch(deleteProviderApi(deleteData.id || deleteData?._id)).unwrap();
      await refreshUsers();
      dispatch(setLoading(false));
      toast.success('User deleted successfully!');
      setDeleteData(null);

      const notifyObj = {

        title: `User Removed Successfully`,
        message: `The user "${deleteData?.firstName} ${deleteData?.lastName}" has been removed from the system successfully.`,
        type: "System",
        userId: userId,
        role: role
      };

      await dispatch(createNotificationApi(notifyObj)).unwrap();

    } catch (error) {

      const notifyObj = {
        title: `Error Removing User`,
        message: `An issue occurred while trying to remove the user. Please try again later.`,
        type: "System",
        userId: userId,
        role: role
      };
      await dispatch(createNotificationApi(notifyObj)).unwrap();
      dispatch(setLoading(false));
      return axiosErrorHandling(error);
    }
  }

  return (
    <>
      {
        deleteStatus && (
          <ModalBox
            open={deleteStatus}
            title='Confirmation'
            width='sm'
            content={getContent()}
            onSubmitTitle='Yes'
            onSubmit={onSubmitDeleteUser}
            onCancelTitle='No'
            onCancel={() => {
              setDeleteStatus(false);
              setDeleteData(null);
            }}
          />
        )
      }
    </>
  )
}

export default DeleteProviderUser;
