/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Tabs, Spin } from 'antd';
import _ from 'lodash';
import PatientDetails from './PatientDetails';
import InsuranceDetails from './InsuranceDetails';
import ProductDetails from './ProductDetails';
import CoverageDetails from './CoverageDetails';
import CallingDetails from './CallingDetails';
import VersionHistory from '../../components/VersionHistory';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveTab,
  setCallerSpaceToggle,
  setCallerSpaceVisible,
  setPatientDetailsSaved,
  setInsuranceDetailsSaved,
  setProductDetailsSaved,
  setCoverageDetailsSaved,
  setCallingDetailsSaved,
} from '../../redux/ChartViewManagement/ChartViewManagementSlice';

const { TabPane } = Tabs;

const UpdateTabPanel = ({
  editPatientData,
  setEditPatientData,
  // setCallerSpaceVisible
}) => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.userDetails.role);

  const [activeSubTab, setActiveSubTab] = useState('1');
  const [loadings, setLoadings] = useState(false);

  const callerSpaceToggle = useSelector((state) => state.chartViewManagement.callerSpaceToggle);
  const callerSpaceVisible = editPatientData?.hasOwnProperty('callerSpaceVisible') ? editPatientData?.callerSpaceVisible : false;
  // const callerSpaceVisible = useSelector((state) => state.chartViewManagement.callerSpaceVisible);

  const activeTab = useSelector((state) => state.chartViewManagement.activeTab);

  const [batchChanged, setBatchChanged] = useState(false);
  const isFromUpdate = true;


  useEffect(() => {
    if (!callerSpaceVisible) {
      if ((editPatientData?.vfCategory?.toLowerCase() === "VF In-Process"?.toLowerCase() ||
        editPatientData?.vfCategory?.toLowerCase() === "Missing Demo/Insurance Info"?.toLowerCase() ||
        editPatientData?.vfCategory?.toLowerCase() === "In-Active"?.toLowerCase() ||
        editPatientData?.vfCategory?.toLowerCase() === "Subsequent VF In-Process"?.toLowerCase()
  
      ) || editPatientData?.hasOwnProperty('callingBy')) {
        dispatch(setCallerSpaceToggle(true));
        dispatch(setCallerSpaceVisible(true));
  
      }
      if ((((role === "AUDIT" || role === "AUDIT CALLER" || role === "VERIFICATION CALLER") && editPatientData?.hasOwnProperty('callingBy'))) ||
        (
          editPatientData?.vfCategory?.toLowerCase() === "VF In-Process"?.toLowerCase() ||
          editPatientData?.vfCategory?.toLowerCase() === "Missing Demo/Insurance Info"?.toLowerCase() ||
          editPatientData?.vfCategory?.toLowerCase() === "In-Active"?.toLowerCase() ||
          editPatientData?.vfCategory?.toLowerCase() === "Subsequent VF In-Process"?.toLowerCase()
        )
      ) {
        dispatch(setCallerSpaceToggle(true));
        dispatch(setCallerSpaceVisible(true));
  
      } else {
        dispatch(setCallerSpaceToggle(false));
      }
    }
  }, [callerSpaceVisible, dispatch, editPatientData, role]);

  return (
    <div>
      <Tabs
        activeKey={activeTab && activeTab}
        size="small"
        onChange={(key) => dispatch(setActiveTab(key))}
      >
        <TabPane tab="Patient Details" key="1">
          <PatientDetails
            chartDetail={editPatientData}
            setActiveSubTab={setActiveSubTab}
            setLoadings={setLoadings}
            setEditPatientData={setEditPatientData}
            setPatientDetailsSaved={setPatientDetailsSaved}
            setBatchChanged={setBatchChanged}
          />
        </TabPane>
        <TabPane tab="Insurance Information" key="2">
          <InsuranceDetails
            chartDetail={editPatientData}
            setActiveSubTab={setActiveSubTab}
            setLoadings={setLoadings}
            setEditPatientData={setEditPatientData}
            setInsuranceDetailsSaved={setInsuranceDetailsSaved}
          />
        </TabPane>
        <TabPane tab="Order Summary" key="3">
          <ProductDetails
            chartDetail={editPatientData}
            setActiveSubTab={setActiveSubTab}
            setLoadings={setLoadings}
            setEditPatientData={setEditPatientData}
            setProductDetailsSaved={setProductDetailsSaved}

          />
        </TabPane>
        <TabPane tab="Coverage Summary" key="4">
          <CoverageDetails
            chartDetail={editPatientData}
            setLoadings={setLoadings}
            setEditPatientData={setEditPatientData}
            setActiveSubTab={setActiveSubTab}
            setCallerSpaceToggle={setCallerSpaceToggle}
            setCoverageDetailsSaved={setCoverageDetailsSaved}
            isFromUpdate={isFromUpdate}
            callerSpaceToggle={callerSpaceToggle}
            callerSpaceVisible={callerSpaceVisible}
            setCallerSpaceVisible={setCallerSpaceVisible}
          />
        </TabPane>
        {callerSpaceVisible && <TabPane tab="Caller Space" key="5">
          <CallingDetails
            chartDetail={editPatientData}
            setLoadings={setLoadings}
            setEditPatientData={setEditPatientData}
            setCallingDetailsSaved={setCallingDetailsSaved}
            callerSpaceToggle={callerSpaceToggle}
            isFromUpdate={isFromUpdate}
          />
        </TabPane>}
        {editPatientData?.versions?.length > 0 && (
          <TabPane tab="Version History" key="6">
            <VersionHistory
              currentChart={editPatientData.chartDetail}
              versions={editPatientData?.versions || []}
            />
          </TabPane>
        )}
      </Tabs>
      <Spin spinning={loadings} fullscreen />

    </div>
  );
};

export default UpdateTabPanel;
