import React, { memo } from "react";
import { motion } from "framer-motion";

// Word wrapper
const Wrapper = (props) => {
  return <span className="word-wrapper">{props.children}</span>;
};

// Map "type" values to JSX tag names
const tagMap = {
  paragraph: "p",
  heading1: "h1",
  heading2: "h2",
};

const HomeEntryText = (props) => {
  // Framer Motion variant for animation
  const item = {
    hidden: {
      x: "-100%", // Starts the text off-screen to the left
      opacity: 0,
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.5 },
    },
    visible: {
      x: 0, // Moves the text to its original position
      opacity: 1,
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.6 },
    },
  };
  //new additions
  if (typeof props.text !== "string") {
    const Tag = tagMap[props.type];
    return (
      <Tag className="text-4xl font-light leading-relaxed text-black">
        <motion.span
          className="inline-block"
          variants={item}
          initial="hidden"
          animate="visible"
        >
          {props.text}
        </motion.span>
      </Tag>
    );
  }


  // Split text into words and letters
  const splitWords = props.text.split(" ");
  const words = splitWords.map((word) => word.split(""));

  // Add a non-breaking space after each word
  words.forEach((word) => word.push("\u00A0"));

  // Get the tag type
  const Tag = tagMap[props.type];

  return (
    <Tag className="text-4xl font-light leading-relaxed text-black">
      {
        words.map((word, index) => (
          <Wrapper key={index}>
            {word.map((letter, idx) => (
              <span
                key={idx}
                className="inline-block overflow-hidden"
                style={{ display: "inline-block" }}
              >
                <motion.span
                  className="inline-block"
                  variants={item}
                  initial="hidden"
                  animate="visible"
                >
                  {letter}
                </motion.span>
              </span>
            ))}
          </Wrapper>
        ))
      }
    </Tag>
  );
};

export default memo(HomeEntryText);
