import api from "../../api";

const baseUrl = "/api/timeZone";

export const getTimeZones = () => {
  return api.get(baseUrl, {
    withCredentials: true, // Ensures credentials (cookies, etc.) are sent with the request
  });
};

