import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/loading/loadingSlice';
import { Col, Form, Input, Row, Select } from 'antd';
import { axiosErrorHandling, isEmptyObject, isObject, trimObjValues } from '../../utils/utility';
import { toast } from 'react-toastify';
import { createProviderApi } from '../../redux/provider/providerService';
import ModalBox from '../../components/ModalBox';
import runes from 'runes2';
import { createNotificationApi } from '../../redux/notification/notificationService';

const roleMapping = [
  { value: 'VIEW', label: 'Visitor Viewer' },
  { value: 'DOWNLOAD', label: 'Report Manager' },
];

const CreateProviderUser = ({
  createStatus,
  setCreateStatus,
  providerList,
  selectTimeZoneList,
  refreshUsers
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [passwordStrength, setPasswordStrength] = useState({
    lowercase: false,
    uppercase: false,
    specialChar: false,
    number: false,
    length: false,
  });

  const userId = useSelector((state) => state.userDetails.userId);
  const project = useSelector((state) => state.userDetails.project);
  const userRole = useSelector((state) => state.userDetails.role);
  const { role } = useSelector((state) => state.userDetails);

  const handlePasswordChange = (value) => {
    // Password validation checks
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /[0-9]/;

    setPasswordStrength({
      lowercase: lowercaseRegex.test(value),
      uppercase: uppercaseRegex.test(value),
      specialChar: specialCharRegex.test(value),
      number: numberRegex.test(value),
      length: value.length >= 8,
    });
  };

  const validatePassword = (_, value) => {
    if (
      passwordStrength.lowercase &&
      passwordStrength.uppercase &&
      passwordStrength.specialChar &&
      passwordStrength.number &&
      passwordStrength.length
    ) {
      return Promise.resolve();
    }
    return Promise.reject(
      'Password must be 8 characters or more, include at least one lowercase character, one uppercase character, one special character, and one number.'
    );
  };

  const onSubmitCreateUser = async () => {
    try {
      const values = await form.validateFields();
      const items = { ...values };
      // Check if the rest of the form data is an object
      if (!isObject(items)) {
        return toast.warning('This is not object data!');
      }

      // Check if form data is empty
      if (isEmptyObject(items)) {
        return toast.warning('Empty object cannot accept!');
      }

      // Trim values
      trimObjValues(items);


      // Check if email already exists
      const emailResult = providerList.filter(
        (item) => item?.email?.toLowerCase() === items?.email?.toLowerCase()
      );
      if (emailResult.length > 0) {
        form.setFields([
          {
            name: 'email',
            errors: ['The email already exists!'],
          },
        ]);
        return toast.warning('The email already exists!');
      }

      // Proceed with user creation
      dispatch(setLoading(true));
      items.isProvider = false;
      items.status = "ACTIVE";
      items.project = project;
      items.createdBy = userId;
      items.role = userRole;
      let response = await dispatch(createProviderApi(items)).unwrap();
      setCreateStatus(false);
      await refreshUsers();
      dispatch(setLoading(false));
      toast.success('Successfully created a user!');
      const { createNotificationApi } = await import('../../redux/notification/notificationService');

      const notifyObj = {
        title: `New User Added`,
        message: `A new user, ${response?.firstName} ${response?.lastName}, has been successfully added to the account successfully.`,
        type: "System",
        userId: userId,
        role: role
      };
      await dispatch(createNotificationApi(notifyObj)).unwrap();

    } catch (error) {
      const notifyObj = {
        title: `Error Adding New User`,
        message: `An error occurred while trying to add the user. Please try again later.`,
        type: "System",
        userId: userId,
        role: role
      };
      await dispatch(createNotificationApi(notifyObj)).unwrap();

      if (error === "Email address already exists!") {
        form.setFields([
          {
            name: 'email',
            errors: ['The email already exists!'],
          },
        ]);
      }
      dispatch(setLoading(false));
      return axiosErrorHandling(error);
    }
  };

  const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    const cleaned = value.replace(/\D/g, '');

    // Limit the length to 10 digits
    const limited = cleaned.slice(0, 10);

    // Format the phone number according to (999) 999-9999 pattern
    const match = limited.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      const [, areaCode, centralOfficeCode, lineNumber] = match;
      if (lineNumber) {
        return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
      } else if (centralOfficeCode) {
        return `(${areaCode}) ${centralOfficeCode}`;
      } else if (areaCode) {
        return `(${areaCode}`;
      }
    }
    return value;
  };


  const getContent = () => {
    const filterOption = (input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
      <Row gutter={16}>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmitCreateUser}
            autoComplete="off"
          >
            <Form.Item
              className="mb-2"
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: 'Please enter the first name!' },
                {
                  pattern: /^[a-zA-Z0-9_\-()' ]+$/,
                  message: 'Special characters other than -, _, (, ), \' and space are not allowed in names!'
                }
              ]}
            >
              <Input
                count={{
                  show: true,
                  max: 25,
                  strategy: (txt) => runes(txt).length,
                  exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(''),
                }}
              />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: 'Please enter the last name!' },
                {
                  pattern: /^[a-zA-Z0-9_\-()' ]+$/,
                  message: 'Special characters other than -, _, (, ), \' and space are not allowed in names!'
                }
              ]}
            >
              <Input
                count={{
                  show: true,
                  max: 25,
                  strategy: (txt) => runes(txt).length,
                  exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(''),
                }}
              />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'This is not a valid email!',
                },
                { required: true, message: 'Please enter the email!' },
                { whitespace: true },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter the password!' },
                { validator: validatePassword },
              ]}
            >
              <Input.Password
                onChange={(e) => handlePasswordChange(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="User Role"
              name="access"
              rules={[
                { required: true, message: 'Please select the user access!' },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select user access"
                optionFilterProp="children"
                filterOption={filterOption}
                options={roleMapping}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmitCreateUser}
            autoComplete="off"
          >
            <Form.Item
              className="mb-2"
              label="Professional Credentials"
              name="credentials"
              rules={[
                { required: true, message: 'Please enter the professional credentials!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Phone Number"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: 'Please enter the phone number or specify "or "N/A" or "N/A"',
                },
                {
                  pattern: /^(\(\d{3}\) \d{3}-\d{4})|(U\/A)|(N\/A)$/i,
                  message: 'Please enter the phone number in the  format (e.g., (999) 153-5556), or specify "U/A" or "N/A"',
                },
              ]}
            >
              <Input
                className="cursor-pointer"
                maxLength={14} // Setting the maximum length of the input field to 14 characters
                onInput={(e) => {
                  e.target.value = formatPhoneNumber(e.target.value);
                }}
                onKeyPress={(e) => {
                  // Allow only numbers, control keys, 'U', 'A', 'N', '/' and ' '
                  if (
                    !/[0-9]/.test(e.key) &&
                    e.key !== 'Backspace' &&
                    e.key !== 'Delete' &&
                    e.key !== 'ArrowLeft' &&
                    e.key !== 'ArrowRight' &&
                    e.key !== 'U' &&
                    e.key !== 'A' &&
                    e.key !== 'N' &&
                    e.key !== '/' &&
                    e.key !== ' '
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Job Role/Title"
              name="jobTitle"
              rules={[
                { required: true, message: 'Please enter the job title!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Organization Name"
              name="organizationName"
              rules={[
                { required: true, message: 'Please enter the organization name!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Time Zone"
              name="timeZone"
              rules={[
                { required: true, message: 'Please select the time zone!' },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select a time zone"
                optionFilterProp="children"
                filterOption={filterOption}
                options={selectTimeZoneList}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {createStatus && (
        <ModalBox
          open={createStatus}
          title="Create User"
          width="md"
          content={getContent()}
          onSubmitTitle="Save"
          onSubmit={onSubmitCreateUser}
          onCancelTitle="Cancel"
          onCancel={() => {
            setCreateStatus(false);
          }}
        />
      )}
    </>
  );
};

export default CreateProviderUser;
